import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeLinkWithAlexaDialog } from 'src/common/actions/dialog';
import {
  handleAlexaFailure,
  handleAlexaSuccess,
} from 'src/common/actions/partners';
import { logClientError, logLinkAlexaAction } from '../../actions/logging';
import feature from '../../constants/analytics/categoryActionLabel/feature';
import {
  ALEXA_DIALOG_BUTTON_LABEL,
  ALEXA_DIALOG_FAIL_TITLE,
  ALEXA_DIALOG_SUBTITLE,
  ALEXA_DIALOG_SUCCESS_BUTTON_LABEL,
  ALEXA_DIALOG_SUCCESS_TITLE,
  ALEXA_DIALOG_TITLE,
} from '../../constants/localizations/devices';
import { config, logging } from '../../constants/partners/alexa';
import withStandardWindowControls from '../../decorators/withStandardWindowControls';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { isXSmall } from '../../utils/breakpoints';
import { BaseLoader } from '../Loader';
import PillButton from '../shared/button/PillButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import css from './link-with-alexa-dialog.module.scss';

// exported for testing
export class LinkWithAlexaDialog extends Component {
  static propTypes = {
    dialogIsOpen: PropTypes.bool.isRequired,
    breakpoint: PropTypes.number,
    // from mapStateToProps
    hasFailed: PropTypes.bool.isRequired,
    isLinked: PropTypes.bool.isRequired,
    isLinking: PropTypes.bool.isRequired,
    // from mapDispatchToProps
    actions: PropTypes.object.isRequired,
    lwaUrl: PropTypes.string,
    // from decorator
    openStandardWindow: PropTypes.func.isRequired,
    closeStandardWindow: PropTypes.func.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.handleFail = this.handleFail.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  componentDidMount() {
    if (this.props.dialogIsOpen) {
      const { actions } = this.props;

      actions.logLinkAlexaAction(feature.labels.show);
    }
  }

  componentDidUpdate(prevProps) {
    const { dialogIsOpen, hasFailed, isLinked, actions } = this.props;
    if (dialogIsOpen && dialogIsOpen !== prevProps.dialogIsOpen) {
      actions.logLinkAlexaAction(feature.labels.show);
    }

    if (hasFailed && hasFailed !== prevProps.hasFailed) {
      actions.logLinkAlexaAction(feature.labels.fail);
    }

    if (isLinked && isLinked !== prevProps.isLinked) {
      actions.logLinkAlexaAction(feature.labels.success);
    }
  }

  handleClose() {
    const {
      isLinking,
      actions: { handleClose },
      closeStandardWindow,
    } = this.props;
    if (!isLinking) {
      handleClose();
      closeStandardWindow();
    }
  }

  handleDialogClose() {
    const { actions } = this.props;

    actions.logLinkAlexaAction(feature.labels.dismiss);
    this.handleClose();
  }

  handleButtonClick() {
    const { actions, isLinked, openStandardWindow, lwaUrl } = this.props;

    actions.logLinkAlexaAction(feature.labels.click);
    if (isLinked) {
      this.handleClose();
    } else {
      const windowConfig = {
        url: lwaUrl,
      };
      openStandardWindow(windowConfig, this.handleResponse);
    }
  }

  handleResponse({ data = {}, origin }) {
    const { code, error, errorDescription, messageType } = data;

    if (origin !== config.secureOrigin || messageType !== config.messageType) {
      return;
    }

    if (code) {
      this.handleSuccess(code);
    } else {
      this.handleFail(error, errorDescription);
    }
  }

  handleSuccess(authCode) {
    const { actions, closeStandardWindow } = this.props;

    closeStandardWindow();
    actions.handleAlexaSuccess(authCode);
  }

  handleFail(error, errorDescription) {
    const { actions, closeStandardWindow } = this.props;
    closeStandardWindow();
    actions.handleAlexaFailure();

    if (error || errorDescription) {
      actions.logClientError({
        message: logging.failure,
        context: { error, errorDescription },
      });
    }
  }

  render() {
    const { dialogIsOpen, hasFailed, isLinked, isLinking, breakpoint } =
      this.props;

    if (!dialogIsOpen) {
      return null;
    }

    const { getLocalizedText } = this.context;
    const isPending = !(hasFailed || isLinked);
    const alternateTitle = isLinked
      ? ALEXA_DIALOG_SUCCESS_TITLE
      : ALEXA_DIALOG_FAIL_TITLE;
    const titleTextKey = isPending ? ALEXA_DIALOG_TITLE : alternateTitle;
    const buttonLabelKey = isLinked
      ? ALEXA_DIALOG_SUCCESS_BUTTON_LABEL
      : ALEXA_DIALOG_BUTTON_LABEL;
    const bodyStyle = isXSmall(breakpoint)
      ? { padding: '40px' }
      : { padding: '30px' };

    return (
      <CommonDialog
        data-testid="addDeviceDialog"
        dialogOpen
        disableOverlayClickToClose={isLinking}
        handleDialogClose={this.handleDialogClose}
        closeIconAttributes={{ className: css.closeIcon }}
        overlayClassName="linkAlexaDialogOverlay"
        bodyStyle={bodyStyle}
      >
        {isLinking && <BaseLoader />}
        <div className={css.contentWrapper}>
          <div data-testid="alexaImage" className={css.alexaImage} />
          <div data-testid="alexaTitleText" className={css.title}>
            {getLocalizedText(titleTextKey)}
          </div>
          {isPending && (
            <div data-testid="alexaSubtitleText" className={css.subtitle}>
              {getLocalizedText(ALEXA_DIALOG_SUBTITLE)}
            </div>
          )}
          <PillButton
            id="alexaLinkButton"
            label={getLocalizedText(buttonLabelKey)}
            className={css.button}
            isDisabled={isLinking}
            onClick={this.handleButtonClick}
          />
        </div>
      </CommonDialog>
    );
  }
}

function mapStateToProps(state) {
  const { hasFailed, isLinked, isLinking, lwaUrl } = state.partners.alexa;
  const { breakpoint } = state.app;
  const { linkWithAlexaDialogIsOpen } = state.dialog;

  return {
    dialogIsOpen: linkWithAlexaDialogIsOpen,
    breakpoint,
    hasFailed,
    isLinked,
    isLinking,
    lwaUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logLinkAlexaAction,
        handleAlexaSuccess,
        handleAlexaFailure,
        handleClose: closeLinkWithAlexaDialog,
        logClientError,
      },
      dispatch,
    ),
  };
}

export default flow(
  withStandardWindowControls,
  connect(mapStateToProps, mapDispatchToProps),
)(LinkWithAlexaDialog);
