import interpolateComponents from 'interpolate-components';
import compact from 'lodash/compact';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectIsExpiredWebSubscriber,
  selectIsUserSubscribed,
} from 'src/common/selectors/me';
import {
  closeUpsellAndTrackActivity,
  closeUpsellDialog,
  openUpsellAndTrackActivity,
} from '../../actions/dialog';
import subscribe from '../../constants/analytics/categoryActionLabel/subscribe';
import {
  firstOpenUpsellAttemptCookie,
  subsequentLaunchUpsellDialogCookie,
  upsellDialogCookie,
} from '../../constants/cookies';
import {
  COMMERCIAL_FREE_MUSIC_ENABLED,
  FIRSTLAUNCH_UPSELL_CTA_COLOR,
  FIRSTLAUNCH_UPSELL_CTA_TEXT,
  FIRSTLAUNCH_UPSELL_DIALOG_ENABLED,
  FIRSTLAUNCH_UPSELL_H1_TEXT,
  SUBSEQUENT_LAUNCH_UPSELL_DIALOG_ENABLED,
  SUBSEQUENT_LAUNCH_UPSELL_DIALOG_X_DAYS,
  UPSELL_CTA_TEXT,
} from '../../constants/experiments/upsell';
import {
  LINE_BREAK_INTERPOLATE,
  PREMIUM,
} from '../../constants/localizations/shared';
import {
  UPSELL_CANCEL_ANYTIME,
  UPSELL_COMMERCIAL_FREE_MUSIC,
  UPSELL_COMMERCIAL_FREE_NEWS,
  UPSELL_COMMERCIAL_FREE_SPORTS,
  UPSELL_DIALOG_PREMIUM_OFFERING,
  UPSELL_GO_PREMIUM,
  UPSELL_LESS_ADS,
  UPSELL_MORERADIO_LESSADS_GOPREMIUM,
  UPSELL_START_YOUR_FREE_TRIAL,
  UPSELL_TRY_PREMIUM,
  UPSELL_UPGRADE_NOW,
} from '../../constants/localizations/upsell';
import { ONE_DAY_IN_MILLIS } from '../../constants/time';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import cssVariables from '../../styles/variables';
import { getCookie, setCookie } from '../../utils/cookie';
import createSubscribeLink from '../../utils/subscription/createSubscribeLink';
import isContentPage from '../../utils/subscription/isContentPage';
import ImageWithDefault from '../shared/ImageWithDefault';
import PillButtonLink from '../shared/button/PillButtonLink';
import CommonDialog from '../shared/dialog/CommonDialog';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';
import css from '../subscription/upsell.module.scss';

const styles = {
  content: {
    maxWidth: 510,
    overflow: 'hidden',
  },
};

const svgProps = {
  width: '20px',
  height: '15px',
  viewBox: '0 0 13 11',
  fill: cssVariables['--primary-color-1'],
  className: css.checkmark,
};

const dataTestIdPrefix = 'upsellDialog';

class PremiumUpsellDialog extends Component {
  static propTypes = {
    routeProps: PropTypes.object.isRequired,
    isUserSubscribed: PropTypes.bool,
    isExpiredWebSubscriber: PropTypes.bool,
    isUpsellOpen: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    [FIRSTLAUNCH_UPSELL_DIALOG_ENABLED]: PropTypes.bool,
    [COMMERCIAL_FREE_MUSIC_ENABLED]: PropTypes.bool,
    [SUBSEQUENT_LAUNCH_UPSELL_DIALOG_ENABLED]: PropTypes.bool,
    [SUBSEQUENT_LAUNCH_UPSELL_DIALOG_X_DAYS]: PropTypes.number,
    sourceLabel: PropTypes.string,
    sourceGuideId: PropTypes.string,
    topicGuideId: PropTypes.string,
    products: PropTypes.array,
    image: PropTypes.string,
    title: PropTypes.string,
    dialogOpenRequest: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.getTextByExperimentOrTranslationKey =
      this.getTextByExperimentOrTranslationKey.bind(this);
    this.openFirstLaunchDialog = this.openFirstLaunchDialog.bind(this);
    this.openSubsequentLaunchDialog =
      this.openSubsequentLaunchDialog.bind(this);
  }

  componentDidMount() {
    const { isUpsellOpen, actions } = this.props;

    // resets dialog state in redux store if route changed and state is set to true
    if (isUpsellOpen) {
      actions.closeUpsellDialog();
      return;
    }

    this.openUpsellOnNav();
  }

  getTextByExperimentOrTranslationKey(
    testKey,
    defaultTextKey,
    wasSubscribedKey,
    overrideKey,
  ) {
    // overwrite default text key if the user was previously subscribed
    const defaultKey =
      this.props.isExpiredWebSubscriber && wasSubscribedKey
        ? wasSubscribedKey
        : defaultTextKey;
    const overrideText = this.props[overrideKey]
      ? this.context.getLocalizedText(this.props[overrideKey])
      : null;
    // check to see if we have an experiment for this item or just use the default
    return (
      overrideText ||
      this.props[testKey] ||
      this.context.getLocalizedText(defaultKey)
    );
  }

  /**
   * openUpsellOnNav() relies on two experiments:
   *
   * First launch disabled, subsequent launch disabled
   *    Dialog does NOT open
   *
   * First launch enabled, subsequent launch disabled
   *    Dialog opens on:
   *      first launch
   *
   * First launch disabled, subsequent launch enabled
   *    Dialog opens on:
   *      subsequent launch, after 24 hours have passed
   *      subsequent launches, after subsequentLaunchUpsellXDays have passed
   *
   * First launch enabled, subsequent launch enabled
   *    Dialog opens on:
   *      first launch
   *      subsequent launch, after 24 hours have passed
   *      subsequent launches, after subsequentLaunchUpsellXDays have passed
   */
  openUpsellOnNav() {
    const {
      actions,
      routeProps,
      isUserSubscribed,
      dialogOpenRequest,
      [FIRSTLAUNCH_UPSELL_DIALOG_ENABLED]: firstLaunchUpsellDialogEnabled,
      [SUBSEQUENT_LAUNCH_UPSELL_DIALOG_ENABLED]: subsequentLaunchUpsellEnabled,
    } = this.props;

    if (
      isUserSubscribed ||
      !isContentPage(routeProps) ||
      (!firstLaunchUpsellDialogEnabled && !subsequentLaunchUpsellEnabled)
    ) {
      return;
    }

    // if first launch is enabled and cookie has not been set
    if (firstLaunchUpsellDialogEnabled && !getCookie(upsellDialogCookie.name)) {
      dialogOpenRequest(this.openFirstLaunchDialog, actions.closeUpsellDialog);
      return;
    }

    const firstOpenUpsellAttemptTimestamp = getCookie(
      firstOpenUpsellAttemptCookie.name,
    );

    // if first time attempting to open upsell
    if (!firstOpenUpsellAttemptTimestamp) {
      // set cookie with the current timestamp
      setCookie(firstOpenUpsellAttemptCookie.name, null, Date.now());
      return; // prevents subsequent-launch logic from triggering dialog on first open attempt
    }

    const timeSinceFirstOpenUpsellAttempt =
      Date.now() - firstOpenUpsellAttemptTimestamp;

    // if subsequent launch experiment is enabled, cookie has not been set, and
    // wait period has passed since first open attempt
    if (
      subsequentLaunchUpsellEnabled &&
      !getCookie(subsequentLaunchUpsellDialogCookie.name) &&
      timeSinceFirstOpenUpsellAttempt > ONE_DAY_IN_MILLIS
    ) {
      dialogOpenRequest(
        this.openSubsequentLaunchDialog,
        actions.closeUpsellDialog,
      );
    }
  }

  openFirstLaunchDialog() {
    const { routeProps, actions } = this.props;
    const currentGuideId = routeProps.guideContext.guideId;

    // open with tracking values for current page and set cookie
    actions.openUpsellAndTrackActivity(
      subscribe.labels.firstloadUpsell,
      currentGuideId,
    );
    setCookie(upsellDialogCookie.name, null, Date.now());
  }

  openSubsequentLaunchDialog() {
    const {
      routeProps,
      actions,
      [SUBSEQUENT_LAUNCH_UPSELL_DIALOG_X_DAYS]: subsequentLaunchUpsellXDays,
    } = this.props;
    const currentGuideId = routeProps.guideContext.guideId;

    // open with tracking values for current page and set cookie
    actions.openUpsellAndTrackActivity(
      subscribe.labels.subsequentLaunchUpsell,
      currentGuideId,
    );

    setCookie(
      subsequentLaunchUpsellDialogCookie.name,
      subsequentLaunchUpsellXDays,
    );
  }

  handleClose(event) {
    if (event) event.preventDefault();
    const { actions, handleDialogClose } = this.props;
    actions.closeUpsellAndTrackActivity(subscribe.actions.cancel);
    handleDialogClose();
  }

  handleContinue() {
    this.props.actions.closeUpsellAndTrackActivity(subscribe.actions.tap);
  }

  generateGeneralUpsellMarkup() {
    const { getLocalizedText } = this.context;
    const headingText = this.getTextByExperimentOrTranslationKey(
      FIRSTLAUNCH_UPSELL_H1_TEXT,
      UPSELL_TRY_PREMIUM,
      UPSELL_UPGRADE_NOW,
    );
    const commercialFreeMusicEnabled =
      !!this.props[COMMERCIAL_FREE_MUSIC_ENABLED];

    return (
      <div className={css.content}>
        <div>
          <h1
            id={`${dataTestIdPrefix}Headline`}
            data-testid={`${dataTestIdPrefix}Headline`}
            className={css.title}
          >
            {headingText}
          </h1>
          <h1
            id={`${dataTestIdPrefix}SubTitle`}
            data-testid={`${dataTestIdPrefix}Subtitle`}
            className={css.subtitle}
          >
            {getLocalizedText(UPSELL_CANCEL_ANYTIME)}
          </h1>
        </div>
        <table
          className={css.list}
          id={`${dataTestIdPrefix}FeatureList`}
          data-testid={`${dataTestIdPrefix}FeatureList`}
        >
          <tbody>
            <tr
              id={`${dataTestIdPrefix}FeatureListCommercialFreeSports`}
              data-testid={`${dataTestIdPrefix}FeatureListCommercialFreeSports`}
            >
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td>{getLocalizedText(UPSELL_COMMERCIAL_FREE_SPORTS)}</td>
            </tr>
            <tr
              id={`${dataTestIdPrefix}FeatureListCommercialFreeNews`}
              data-testid={`${dataTestIdPrefix}FeatureListCommercialFreeNews`}
            >
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td>{getLocalizedText(UPSELL_COMMERCIAL_FREE_NEWS)}</td>
            </tr>
            {commercialFreeMusicEnabled && (
              <tr
                id={`${dataTestIdPrefix}FeatureListCommercialFreeMusic`}
                data-testid={`${dataTestIdPrefix}FeatureListCommercialFreeMusic`}
              >
                <td>
                  <Checkmark {...svgProps} />
                </td>
                <td>{getLocalizedText(UPSELL_COMMERCIAL_FREE_MUSIC)}</td>
              </tr>
            )}
            <tr
              id={`${dataTestIdPrefix}FeatureListNoAds`}
              data-testid={`${dataTestIdPrefix}FeatureListNoAds`}
            >
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td>{getLocalizedText(UPSELL_LESS_ADS)}</td>
            </tr>
          </tbody>
        </table>
        <div
          className={css.bgImage}
          id={`${dataTestIdPrefix}BackgroundImage`}
          data-testid={`${dataTestIdPrefix}BackgroundImage`}
        />
      </div>
    );
  }

  generateCustomUpsellMarkup() {
    const { image } = this.props;
    const { getLocalizedText } = this.context;

    const title = getLocalizedText(UPSELL_MORERADIO_LESSADS_GOPREMIUM).replace(
      /\{0\}/gi,
      LINE_BREAK_INTERPOLATE,
    );
    return (
      <div className={css.customUpsellContent}>
        <h3 data-testid="premiumLogoSmall" className={css.premiumLogoXSmall}>
          {getLocalizedText(PREMIUM).toUpperCase()}
        </h3>
        <ImageWithDefault
          src={image}
          alt={`${title}-logo`}
          dataTestId="guideImage"
          containerClassName={css.guideImageContainer}
          className={css.guideImage}
        />
        <div className={css.guideTitleContainer}>
          <h3 data-testid="premiumLogoSmall" className={css.premiumLogoSmall}>
            {getLocalizedText(PREMIUM).toUpperCase()}
          </h3>
          <div className={css.title} data-testid="guideTitle">
            {interpolateComponents({
              mixedString: title,
              components: {
                linebreak: <br />,
              },
            })}
          </div>
        </div>
        <div
          className={css.premiumOfferingContainer}
          data-testid="premiumOfferingContainer"
        >
          {getLocalizedText(UPSELL_DIALOG_PREMIUM_OFFERING)}
        </div>
      </div>
    );
  }

  render() {
    const {
      isUpsellOpen,
      sourceLabel,
      sourceGuideId,
      topicGuideId,
      products,
      image,
      title,
      [FIRSTLAUNCH_UPSELL_CTA_COLOR]: firstLaunchButtonColor,
    } = this.props;

    if (!isUpsellOpen) {
      return null;
    }

    const { location } = this.context;
    const isCustomUpsell = !!image && !!title;
    const buttonText = this.getTextByExperimentOrTranslationKey(
      FIRSTLAUNCH_UPSELL_CTA_TEXT,
      UPSELL_START_YOUR_FREE_TRIAL,
      UPSELL_GO_PREMIUM,
      UPSELL_CTA_TEXT,
    );
    const additionalParams = {
      sku: compact(products),
      ...(topicGuideId && { topicid: topicGuideId.slice(1) }),
    };
    const subscribeLink = createSubscribeLink({
      source: sourceLabel,
      guideId: sourceGuideId,
      location,
      additionalParams,
    });
    const upsellMarkup = isCustomUpsell
      ? this.generateCustomUpsellMarkup()
      : this.generateGeneralUpsellMarkup();

    let buttonStyle = {
      buttonBackgroundColor: cssVariables['--secondary-color-5'],
      buttonHoverColor: cssVariables['--ink-light'],
      buttonTextColor: '#ffffff',
    };

    // set colors dependent on type of upsell
    if (isCustomUpsell) {
      buttonStyle = {
        buttonBackgroundColor: cssVariables['--tunein-coral'],
        buttonHoverColor: cssVariables['--tunein-coral-hover'],
        buttonTextColor: cssVariables['--secondary-color-5'],
      };
    }

    if (firstLaunchButtonColor) {
      buttonStyle = {
        buttonBackgroundColor: `#${firstLaunchButtonColor}`,
        buttonHoverColor: undefined,
        buttonTextColor: '#ffffff',
      };
    }

    return (
      <CommonDialog
        modal={false}
        dialogOpen
        handleDialogClose={this.handleClose}
        overlayClassName={`${dataTestIdPrefix}Overlay`}
        bodyClassName={isCustomUpsell ? css.inkDialogBody : css.dialogBody}
        contentStyle={styles.content}
        data-testid={dataTestIdPrefix}
        hasDarkTheme={isCustomUpsell}
        closeIconAttributes={{
          id: 'dialogCornerClose',
          testId: 'dialogCornerClose',
        }}
      >
        <div className={css.dialogWrapper}>
          {upsellMarkup}
          <PillButtonLink
            to={subscribeLink}
            id={`${dataTestIdPrefix}StartLink`}
            dataTestId={`${dataTestIdPrefix}StartLink`}
            onClick={this.handleContinue}
            label={buttonText}
            backgroundColor={buttonStyle.buttonBackgroundColor}
            hoverColor={buttonStyle.buttonHoverColor}
            textColor={buttonStyle.buttonTextColor}
          />
        </div>
      </CommonDialog>
    );
  }
}

function mapStateToProps(state) {
  const { dialog } = state;
  return {
    isUpsellOpen: dialog.isUpsellOpen,
    sourceLabel: dialog.upsellLabel,
    sourceGuideId: dialog.upsellGuideId,
    topicGuideId: dialog.topicGuideId,
    products: dialog.products,
    image: dialog.image,
    title: dialog.title,
    isUserSubscribed: selectIsUserSubscribed(state),
    isExpiredWebSubscriber: selectIsExpiredWebSubscriber(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeUpsellDialog,
        closeUpsellAndTrackActivity,
        openUpsellAndTrackActivity,
      },
      dispatch,
    ),
  };
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  connectWithExperiments([
    FIRSTLAUNCH_UPSELL_DIALOG_ENABLED,
    FIRSTLAUNCH_UPSELL_CTA_COLOR,
    FIRSTLAUNCH_UPSELL_CTA_TEXT,
    FIRSTLAUNCH_UPSELL_H1_TEXT,
    COMMERCIAL_FREE_MUSIC_ENABLED,
    SUBSEQUENT_LAUNCH_UPSELL_DIALOG_ENABLED,
    SUBSEQUENT_LAUNCH_UPSELL_DIALOG_X_DAYS,
    UPSELL_CTA_TEXT,
  ]),
)(PremiumUpsellDialog);
