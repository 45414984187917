import interpolateComponents from 'interpolate-components';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ADS_WILL_FINISH_COUNTDOWN } from 'src/common/constants/localizations/ads';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import {
  selectNowPlaying,
  selectPositionInfo,
} from 'src/common/selectors/player';
import css from './videoAd.module.scss';

export default function VideoAdCountdown() {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const positionInfo = useSelector(selectPositionInfo);
  const nowPlaying = useSelector(selectNowPlaying);

  const { duration = 0, elapsedSeconds = 0 } = positionInfo;
  const timeRemaining = Math.floor(duration - elapsedSeconds);

  if (!timeRemaining) {
    return null;
  }

  return (
    <div className={css.countdownText}>
      {interpolateComponents({
        mixedString: getLocalizedText(ADS_WILL_FINISH_COUNTDOWN),
        components: {
          stationTitle: (
            <span className={css.stationTitle}>{nowPlaying?.subtitle}</span>
          ),
          timeRemaining: (
            <span className={css.timeRemaining}>
              {`00:${timeRemaining.toString().padStart(2, '0')}`}
            </span>
          ),
        },
      })}
    </div>
  );
}
