import classNames from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import css from './customizableDialogView.module.scss';

function Header({ className, overlayClassName, children, style = {} }, ref) {
  return (
    <div ref={ref} className={classNames(css.header, className)} style={style}>
      <div className={classNames(css.headerOverlay, overlayClassName)}>
        {children}
      </div>
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  style: PropTypes.object,
};

export default forwardRef(Header);
