import classNames from 'clsx';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  closeNowPlayingDialog,
  closeNowPlayingDialogAndTrack,
  fetchNowPlayingItems,
} from 'src/common/actions/dialog';
import { logMobileWebActivity } from 'src/common/actions/logging';
import { setPageLoadId } from 'src/common/actions/reporting';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import cssVariables from 'src/common/styles/variables';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/constants';
import mobileWeb from '../../constants/analytics/categoryActionLabel/mobileWeb';
import { NOW_PLAYING_GUIDE_ID } from '../../constants/dialogs/nowPlaying';
import { NO_RECENTS_TEXT, RECENTS } from '../../constants/localizations/shared';
import NowPlayingContext from '../../contexts/NowPlayingContext';
import { withPalette } from '../../decorators/withPalette';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import {
  selectIsDiscord,
  selectIsFetching,
  selectIsFord,
} from '../../selectors/app';
import {
  selectExperiment,
  selectIsDesktopNowPlayingEnabled,
} from '../../selectors/config';
import { selectIsUserSubscribed } from '../../selectors/me';
import { isXXSmall } from '../../utils/breakpoints';
import { getCssStyle } from '../../utils/getCssStyle';
import DialogDisplayAd from '../ads/DialogDisplayAd';
import SponsoredRollupProvider from '../ads/SponsoredRollupProvider';
import ContainerItemsLoader from '../containerItems/ContainerItemsLoader';
import NowPlaying from '../containerItems/childPresentations/NowPlaying';
import ListLayout from '../containerItems/list/ListLayout';
import ContainerTitle from '../containerItems/shared/ContainerTitle';
import ScrollReset from '../shared/ScrollReset';
import CommonDialog from '../shared/dialog/CommonDialog';
import { NavHomeTuneInLink } from '../shared/link/NavHomeTuneInLink';
import css from './nowPlayingDialog.module.scss';

const idPrefix = 'nowPlayingDialog';
const MAX_RECENTS_COUNT = 5;
const defaultDiscordBodyStyles = {
  backgroundColor: cssVariables['--medium-grey'],
};

function getPaletteBodyStyles(paletteColor) {
  return {
    background: `linear-gradient(180deg, ${paletteColor}CC, ${cssVariables['--medium-grey']})`,
  };
}

// exported for testing
export class NowPlayingDialog extends Component {
  static propTypes = {
    // from parent
    routeProps: PropTypes.object,
    breakpoint: PropTypes.number.isRequired,

    // from mapStateToProps/mapDispatchToProps
    isNowPlayingDialogOpen: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    currentlyPlayingGuideId: PropTypes.string.isRequired,
    nowPlayingGuideItem: PropTypes.object.isRequired,
    recentsGuideItem: PropTypes.object.isRequired,
    selectedContentId: PropTypes.string,
    isDesktopNowPlayingEnabled: PropTypes.bool.isRequired,
    isDarkMode: PropTypes.bool,
    isUserSubscribed: PropTypes.bool,
    isDiscord: PropTypes.bool,
    isAppFetchingData: PropTypes.bool,
    isFreestarEnabled: PropTypes.bool.isRequired,

    // from withRouter
    history: PropTypes.object,

    // from withUsePalette
    palette: PropTypes.object,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  state = {
    scrollHash: 0,
  };

  componentDidMount() {
    const { isNowPlayingDialogOpen, actions, isDiscord } = this.props;

    if (isDiscord) {
      this.deregisterHistoryListener = this.props.history.listen(
        this.onHistoryChange,
      );
    }

    // NOTE: Handles directToNowPlaying reporting scenario...
    // where NowPlayingDialog mounts with isNowPlayingDialogOpen set to true via server
    if (isNowPlayingDialogOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      actions.logMobileWebActivity(
        mobileWeb.actions.show,
        mobileWeb.labels.nowPlayingDialog,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isNowPlayingDialogOpen,
      actions,
      currentlyPlayingGuideId,
      isAppFetchingData,
      isDiscord,
    } = this.props;

    if (isNowPlayingDialogOpen && !prevProps.isNowPlayingDialogOpen) {
      // NOTE: Calling fetchNowPlayingItems without currentlyPlayingGuideId will break NowPlaying Containers
      if (currentlyPlayingGuideId) {
        actions.setPageLoadId();
        actions.fetchNowPlayingItems(currentlyPlayingGuideId);
      }

      // eslint-disable-next-line react/no-did-update-set-state
      actions.logMobileWebActivity(
        mobileWeb.actions.show,
        mobileWeb.labels.nowPlayingDialog,
      );
      return;
    }

    // When the NP dialog is open on Discord, close NP if a route change occurred, as indicated by the app data-fetching
    // state changing to false. Using a setTimeout allows rendering to finish on the page prior to closing the NP dialog.
    if (
      isNowPlayingDialogOpen &&
      isDiscord &&
      !isAppFetchingData &&
      prevProps.isAppFetchingData
    ) {
      setTimeout(actions.closeNowPlayingDialog);
    }
  }

  componentWillUnmount() {
    this.deregisterHistoryListener?.();
  }

  handleClose() {
    this.props.actions.closeNowPlayingDialogAndTrack();
  }

  // Listens to the router history and closes the NP dialog if a user clicks on a link that matches the current route.
  // For example, by clicking on the title in the player (visible on desktop Discord) or the profile link under the
  // title in the NP dialog.
  // If a user clicks on a link that navigates to a *different* route, we have a data-fetching listener in
  // componentDidUpdate that handles closing the NP dialog. We don't handle that here because data-fetching times are
  // variable (e.g., due to network conditions), so we don't want to optimistically close the NP dialog while a stale
  // page is present, which would suddenly be visually replaced by a new page.
  onHistoryChange = (location) => {
    const { actions } = this.props;
    const { locationPathname } = this.state;

    if (locationPathname && locationPathname === location.pathname) {
      actions.closeNowPlayingDialog();
    }

    this.setState({ locationPathname: location.pathname });
  };

  scrollToTop() {
    this.setState((state) => ({
      scrollHash: state.scrollHash + 1,
    }));
  }

  render() {
    const {
      isNowPlayingDialogOpen,
      breakpoint,
      nowPlayingGuideItem,
      recentsGuideItem,
      currentlyPlayingGuideId,
      routeProps,
      isDesktopNowPlayingEnabled,
      isUserSubscribed,
      isDiscord,
      palette,
      isFreestarEnabled,
    } = this.props;
    const { getLocalizedText } = this.context;

    // if this logic is removed we'll need to deregister the ad slot from mint
    if (!isNowPlayingDialogOpen) {
      return null;
    }

    // NOTE: currentlyPlayingGuideId not avail when leveraging SSR for directToNowPlaying UX
    const guideIdToPlay = get(routeProps, 'guideContext.guideId');
    const activeGuideId = currentlyPlayingGuideId || guideIdToPlay;
    const playerHeight = getCssStyle('--player-height');
    const matchurl = get(routeProps, 'matchUrl', '');
    const paletteColor =
      (!palette?.loading && !palette?.error && palette?.data?.vibrant) || null;
    const discordBodyStyle = paletteColor
      ? getPaletteBodyStyles(paletteColor)
      : defaultDiscordBodyStyles;
    const bodyStyle = isDiscord ? discordBodyStyle : {};
    const containerItems = isXXSmall(breakpoint)
      ? nowPlayingGuideItem.containerItems
      : nowPlayingGuideItem.containerItems.slice(0, 1);

    return (
      <CommonDialog
        modal
        bodyClassName={css.dialog}
        contentClassName={css.dialogContent}
        style={
          isDesktopNowPlayingEnabled
            ? { height: `calc(100vh - ${playerHeight})` }
            : undefined
        }
        bodyStyle={bodyStyle}
        overlayClassName={
          isDesktopNowPlayingEnabled ? css.dialogOverlay : undefined
        }
        dialogOpen
        handleDialogClose={this.handleClose}
        data-testid={idPrefix}
        hasDarkTheme
        hideCornerClose
      >
        <div className={css.containerPage}>
          <div className={css.badge}>
            <NavHomeTuneInLink />
          </div>
          <NowPlayingContext.Provider value={{ scrollToTop: this.scrollToTop }}>
            <ScrollReset
              className={classNames('scroller', css.scrollReset)}
              scrollHash={this.state.scrollHash}
            >
              <div
                className={classNames(css.leftSide, {
                  [css.desktop]: isDesktopNowPlayingEnabled,
                })}
              >
                <div className={css.containerItems}>
                  {containerItems ? (
                    <SponsoredRollupProvider guideId={NOW_PLAYING_GUIDE_ID}>
                      <ContainerItemsLoader
                        guideItem={nowPlayingGuideItem}
                        routeProps={routeProps}
                        itemsStyles={get(
                          nowPlayingGuideItem,
                          'metadata.styles',
                          {},
                        )}
                        items={containerItems}
                        isFetching={false}
                        breakpoint={breakpoint}
                        selectedContentId={activeGuideId}
                        isNowPlaying
                        isDarkMode
                      />
                    </SponsoredRollupProvider>
                  ) : (
                    <NowPlaying
                      isInNowPlayingDialog
                      hideDisplayAd
                      breakpoint={breakpoint}
                      selectedContentId={activeGuideId}
                      matchUrl={get(routeProps, 'matchUrl', '')}
                    />
                  )}
                </div>
              </div>
            </ScrollReset>
            {isDesktopNowPlayingEnabled && (
              <div className={css.rightSide}>
                {!isUserSubscribed && (
                  <div data-darkmode>
                    <DialogDisplayAd
                      isProfile={false}
                      matchurl={matchurl}
                      guideId={activeGuideId}
                      classname={css.ad}
                      {...getDisplayAdAttributes(
                        gptSlotNames.nowPlaying_side,
                        isFreestarEnabled,
                      )}
                    />
                  </div>
                )}
                {isUserSubscribed && recentsGuideItem.children && (
                  <div data-darkmode>
                    <ListLayout
                      breakpoint={breakpoint}
                      selectedContentId={activeGuideId}
                      matchUrl={matchurl}
                      id="nowPlayingRecents"
                      containerItem={recentsGuideItem}
                      containerTitle={getLocalizedText(RECENTS)}
                      maxChildren={MAX_RECENTS_COUNT}
                      containerTitleClassName={css.recents}
                    />
                  </div>
                )}
                {isUserSubscribed && !recentsGuideItem.children && (
                  <div data-darkmode>
                    <ContainerTitle
                      containerItem={recentsGuideItem}
                      containerTitle={getLocalizedText(RECENTS)}
                      isNowPlayingDialogOpen
                      actions={{}}
                      className={css.recents}
                    />
                    <div className={css.noRecentsSubtitle}>
                      {getLocalizedText(NO_RECENTS_TEXT)}
                    </div>
                  </div>
                )}
              </div>
            )}
          </NowPlayingContext.Provider>
        </div>
      </CommonDialog>
    );
  }
}

// exported for unit testing
export function mapStateToProps(state) {
  const isNowPlayingDialogOpen = get(
    state,
    'dialog.isNowPlayingDialogOpen',
    false,
  );
  const { player, dialog } = state;
  const currentlyPlayingGuideId = get(player, 'tunedGuideId', '');
  const nowPlayingGuideItem = get(dialog, NOW_PLAYING_GUIDE_ID, {});
  const recentsGuideItem = get(dialog, 'recents', {});

  return {
    isNowPlayingDialogOpen,
    currentlyPlayingGuideId,
    nowPlayingGuideItem,
    recentsGuideItem,
    isDesktopNowPlayingEnabled: selectIsDesktopNowPlayingEnabled(state),
    isUserSubscribed: selectIsUserSubscribed(state),
    isDiscord: selectIsDiscord(state),
    isAppFetchingData: selectIsFetching(state),
    isFreestarEnabled: selectExperiment(state, FREESTAR_ENABLED),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeNowPlayingDialogAndTrack,
        closeNowPlayingDialog,
        logMobileWebActivity,
        fetchNowPlayingItems,
        setPageLoadId,
      },
      dispatch,
    ),
  };
}

const baseHOCs = [withRouter, connect(mapStateToProps, mapDispatchToProps)];
const allHOCs = [
  withPalette,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
];

const applyHOCs = (Component, hocs) => {
  return flow(hocs)(Component);
};

const EnhancedNowPlayingDialog = (props) => {
  const isFord = useSelector(selectIsFord);
  const hocs = useMemo(() => (isFord ? baseHOCs : allHOCs), [isFord]);

  const EnhancedComponent = useMemo(
    () => applyHOCs(NowPlayingDialog, hocs),
    [hocs],
  );

  return <EnhancedComponent {...props} />;
};

export default EnhancedNowPlayingDialog;
