import classNames from 'clsx';
import PropTypes from 'prop-types';

import PillButton from './PillButton';

import css from './pill-button.module.scss';

const SkyButton = ({ className, ...otherProps }) => (
  <PillButton className={classNames(className, css.sky)} {...otherProps} />
);

SkyButton.propTypes = {
  className: PropTypes.string,
};

export default SkyButton;
