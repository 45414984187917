import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  USER_REGISTRATION_DIALOG_GO_PREMIUM_CTA_TEXT,
  USER_REGISTRATION_DIALOG_GO_PREMIUM_SUBTEXT,
} from 'src/common/constants/localizations/userRegistration';
import { logSubscribeActivity } from '../../../actions/logging';
import subscribe from '../../../constants/analytics/categoryActionLabel/subscribe';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import assetUrl from '../../../utils/assetUrl';
import createSubscribeLink from '../../../utils/subscription/createSubscribeLink';
import OutlinedPillButtonLink from '../../shared/button/OutlinedPillButtonLink';
import PremiumBadgeWithText from '../../shared/svgIcons/PremiumBadgeWithText';
import css from './userRegistrationDialog.module.scss';

export class GoPremiumUpsell extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      logSubscribeActivity: PropTypes.func.isRequired,
    }),
    onGoPremiumClick: PropTypes.func.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.logSubscribeActivity(
      subscribe.actions.show,
      subscribe.labels.signUpUpsell,
    );
  }

  handleClick() {
    const { actions, onGoPremiumClick } = this.props;
    actions.logSubscribeActivity(
      subscribe.actions.tap,
      subscribe.labels.signUpUpsell,
    );
    onGoPremiumClick();
  }

  render() {
    const { location, getLocalizedText } = this.context;
    const source = subscribe.labels.signUpUpsell;
    const subscribeLink = createSubscribeLink({ source, location });

    return (
      <div
        data-testid="GoPremiumUpsell"
        className={classNames(css.creativeWrapper, css.goPremium)}
      >
        <PremiumBadgeWithText className={css.premiumBadgeWithText} />
        <img
          className={css.goPremiumImage}
          src={assetUrl('assets/img/horizontal-tiles.png')}
          alt="Go Premium Registration"
        />
        <p className={css.creativeSubtext}>
          {getLocalizedText(USER_REGISTRATION_DIALOG_GO_PREMIUM_SUBTEXT)}
        </p>
        <OutlinedPillButtonLink
          label={getLocalizedText(USER_REGISTRATION_DIALOG_GO_PREMIUM_CTA_TEXT)}
          className={css.userDialogCtaButton}
          to={subscribeLink}
          onClick={this.handleClick}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ logSubscribeActivity }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(GoPremiumUpsell);
