import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cssVariables from 'src/common/styles/variables';
import { logSignInDismiss, logSignUpDismiss } from '../../actions/logging';
import viewTypes from '../../constants/auth/viewTypes';
import connectWithAuth from '../../decorators/auth/connectWithAuth';
import withAuthViewHandling from '../../decorators/auth/withAuthViewHandling';
import CommonDialog from '../shared/dialog/CommonDialog';
import AuthViewContainer from './AuthViewContainer';
import css from './auth-dialog.module.scss';

class AuthDialog extends Component {
  static propTypes = {
    authActions: PropTypes.object.isRequired,
    // from withAuthViewHandling
    history: PropTypes.object.isRequired,
    loginDetails: PropTypes.object.isRequired,
    source: PropTypes.string,
    viewType: PropTypes.string.isRequired,
    logSignInDismiss: PropTypes.func.isRequired,
    logSignUpDismiss: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
  };

  handleClose = () => {
    const { authActions, viewType, handleDialogClose } = this.props;

    authActions.authDone();
    handleDialogClose();

    switch (viewType) {
      case viewTypes.signIn:
        this.props.logSignInDismiss();
        break;
      case viewTypes.signUp:
        this.props.logSignUpDismiss();
        break;
      default:
    }
  };

  render() {
    const { authActions, loginDetails, source, viewType, history } = this.props;

    if (!loginDetails.authDialogOpen) {
      return null;
    }

    return (
      <CommonDialog
        key="loginDialog"
        modal={false}
        dialogOpen
        handleDialogClose={this.handleClose}
        overlayClassName="loginDialogOverlay"
        bodyClassName={css.authDialogBody}
        contentClassName={css.authDialogContent}
        style={{ zIndex: cssVariables['--auth-dialog-z-index'] }}
        closeIconAttributes={{ className: css.closeIcon }}
      >
        <AuthViewContainer
          key="authContainerDialogView"
          authActions={authActions}
          loginDetails={loginDetails}
          source={source}
          viewType={viewType}
          history={history}
          isDialog
          formContainerClassName={css.authDialogFormContainer}
        />
      </CommonDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logSignInDismiss,
      logSignUpDismiss,
    },
    dispatch,
  );
}

export default flow(
  connect(null, mapDispatchToProps),
  withAuthViewHandling(),
  connectWithAuth,
)(AuthDialog);
