import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeErrorDialog } from '../../actions/dialog';
import {
  ERROR_DIALOG_DISMISS,
  ERROR_DIALOG_SUBTITLE,
  ERROR_DIALOG_TITLE,
} from '../../constants/localizations/errorDialog';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import cssVariables from '../../styles/variables';
import PillButton from '../shared/button/PillButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import css from './error-dialog.module.scss';

// exported for testing
export class ErrorDialog extends Component {
  static propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.actions.closeErrorDialog();
  }

  render() {
    const { isDialogOpen } = this.props;
    const { getLocalizedText } = this.context;

    if (!isDialogOpen) {
      return null;
    }

    return (
      <CommonDialog
        modal={false}
        dialogOpen
        handleDialogClose={this.handleClose}
        overlayClassName="errorDialogOverlay"
        data-testid="errorDialog"
        bodyStyle={{
          backgroundColor: cssVariables['--white'],
        }}
        dialogContentStyle={{
          borderRadius: '8px',
        }}
        rootStyle={{
          zIndex: cssVariables['--above-top-z-index'],
        }}
      >
        <h2 data-testid="title" className={css.text}>
          {getLocalizedText(ERROR_DIALOG_TITLE)}
        </h2>
        <h2 data-testid="subtitle" className={css.text}>
          {getLocalizedText(ERROR_DIALOG_SUBTITLE)}
        </h2>
        <PillButton
          id="dismissButton"
          onClick={this.handleClose}
          label={getLocalizedText(ERROR_DIALOG_DISMISS)}
          className={css.dismissButton}
        />
      </CommonDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDialogOpen: state.dialog.isErrorDialogOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeErrorDialog,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);
