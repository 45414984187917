import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logging } from 'src/common/constants/partners/alexa';
import { logClientInfo } from '../actions/logging';
import { checkAlexaLinkStatus, getAlexaUrls } from '../actions/partners';
import isDesktop from '../utils/desktop/isDesktop';

import { ALEXA_LINKING_ENABLED } from '../constants/experiments/partners';
import { selectIsUserSubscribed } from '../selectors/me';
import connectWithExperiments from './connectWithExperiments';

function mapStateToProps(state) {
  const { isLinked, lwaUrl, urlFetchFail } = state.partners.alexa;
  return {
    isAlexaLinked: isLinked,
    isUserSubscribed: selectIsUserSubscribed(state),
    urlFetchFail,
    lwaUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logClientInfo,
        checkAlexaLinkStatus,
        getAlexaUrls,
      },
      dispatch,
    ),
  };
}

export default function withAlexaLinking(WrappedComponent) {
  class AlexaLinkButton extends Component {
    static propTypes = {
      isAlexaLinked: PropTypes.bool.isRequired,
      lwaUrl: PropTypes.string,
      urlFetchFail: PropTypes.bool.isRequired,
      isUserSubscribed: PropTypes.bool.isRequired,
      [ALEXA_LINKING_ENABLED]: PropTypes.bool,
      actions: PropTypes.shape({
        logClientInfo: PropTypes.func.isRequired,
        checkAlexaLinkStatus: PropTypes.func.isRequired,
        getAlexaUrls: PropTypes.func.isRequired,
      }).isRequired,
    };

    componentDidMount() {
      const {
        [ALEXA_LINKING_ENABLED]: isAlexaEnabled,
        isUserSubscribed,
        actions,
      } = this.props;

      if (isAlexaEnabled && isUserSubscribed) {
        actions.checkAlexaLinkStatus();
        actions.getAlexaUrls();
      }
    }

    componentDidUpdate(prevProps) {
      const {
        urlFetchFail,
        actions,
        isUserSubscribed,
        [ALEXA_LINKING_ENABLED]: alexaLinkingEnabled,
      } = this.props;
      if (urlFetchFail && !prevProps.urlFetchFail) {
        actions.logClientInfo({
          message: logging.urlFetchFail,
          context: {},
        });
      }

      if (
        alexaLinkingEnabled &&
        isUserSubscribed &&
        isUserSubscribed !== prevProps.isUserSubscribed
      ) {
        actions.checkAlexaLinkStatus();
        actions.getAlexaUrls();
      }
    }

    render() {
      const {
        isAlexaLinked,
        isUserSubscribed,
        lwaUrl,
        [ALEXA_LINKING_ENABLED]: alexaLinkingEnabled,
      } = this.props;

      const isAlexaLinkingEnabled = !!(
        alexaLinkingEnabled &&
        !!lwaUrl &&
        isUserSubscribed &&
        !isDesktop()
      );

      return (
        <WrappedComponent
          isAlexaLinkingEnabled={isAlexaLinkingEnabled}
          isAlexaLinked={isAlexaLinked}
          {...this.props}
        />
      );
    }
  }

  return flow(
    connect(mapStateToProps, mapDispatchToProps),
    connectWithExperiments([ALEXA_LINKING_ENABLED]),
  )(AlexaLinkButton);
}
