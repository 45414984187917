import PropTypes from 'prop-types';
import withAdRegistration from 'src/common/decorators/ads/withAdRegistration';
import css from './videoAd.module.scss';

function VideoAdSlot({ height, id }) {
  return <div id={id} className={css.videoAdSlot} style={{ height }} />;
}

VideoAdSlot.propTypes = {
  slotNames: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
};

export default withAdRegistration({
  autoPlay: false,
  refreshOnPageChange: false,
})(VideoAdSlot);
