import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { closePopOutPlayerDialog } from '../../actions/dialog';
import PopOutButton from '../shared/button/PopOutButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import UnderlineLink from '../shared/link/UnderlineLink';

import {
  POP_OUT_DIALOG_MESSAGE_LINK,
  POP_OUT_DIALOG_MESSAGE_V2,
} from '../../constants/localizations/player';

import { HELP_CHROME80 } from '../../constants/cmsLinks';

import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import css from './popout-player-dialog.module.scss';

const buttonDimensions = {
  width: '45',
  height: '45',
};

export class PopoutPlayerDialog extends Component {
  static propTypes = {
    isDialogOpen: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.actions.closePopOutPlayerDialog();
  }

  render() {
    const { isDialogOpen } = this.props;
    const { getLocalizedText } = this.context;

    if (!isDialogOpen) {
      return null;
    }

    return (
      <CommonDialog
        modal
        dialogOpen
        handleDialogClose={this.handleClose}
        contentStyle={{
          maxWidth: 510,
          overflow: 'hidden',
        }}
      >
        <h2 className={css.messageContainer} data-testid="messageContainer">
          {interpolateComponents({
            mixedString: getLocalizedText(POP_OUT_DIALOG_MESSAGE_V2),
            components: {
              helplink: (
                <UnderlineLink
                  data-testid="helpLink"
                  to={HELP_CHROME80}
                  target="_blank"
                  isNotReactRouterLink
                  isDropDown
                >
                  {getLocalizedText(POP_OUT_DIALOG_MESSAGE_LINK)}
                </UnderlineLink>
              ),
            },
          })}
        </h2>
        <div className={css.buttonContainer}>
          <PopOutButton
            width={buttonDimensions.width}
            height={buttonDimensions.height}
            onClick={this.handleClose}
            dataTestId="popoutPlayerDialogButton"
          />
        </div>
      </CommonDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDialogOpen: state.dialog.isPopoutPlayerDialogOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      closePopOutPlayerDialog: () => dispatch(closePopOutPlayerDialog()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopoutPlayerDialog);
