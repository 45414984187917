import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';

import connectWithAuth from '../../decorators/auth/connectWithAuth';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import { getCookie, setCookie } from '../../utils/cookie';
import AuthDialog from '../auth/AuthDialog';

import { scheduledRegCookie } from '../../constants/cookies';
import {
  WEB_SUBSEQUENT_LAUNCH_REGISTRATION_ENABLED,
  WEB_SUBSEQUENT_LAUNCH_REGISTRATION_SHOW_IN_DAYS,
} from '../../constants/experiments/dialog';
import { ONE_DAY_IN_MILLIS } from '../../constants/time';

class AuthDialogManager extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    routeProps: PropTypes.object.isRequired,
    dialogOpenRequest: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    authActions: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.openScheduledRegistrationDialog =
      this.openScheduledRegistrationDialog.bind(this);
  }

  /**
   * componentDidMount is called on route change, hence "onNavigation"
   * is being called when appropriate.
   *
   * See NavBasedDialogManager.
   */
  componentDidMount() {
    const {
      isAuthenticated,
      [WEB_SUBSEQUENT_LAUNCH_REGISTRATION_ENABLED]: isLaunchEnabled,
      [WEB_SUBSEQUENT_LAUNCH_REGISTRATION_SHOW_IN_DAYS]: showInDays,
      routeProps,
    } = this.props;

    if (
      !isAuthenticated &&
      isLaunchEnabled &&
      showInDays &&
      !routeProps.disableScheduledRegistrationDialog
    ) {
      this.onNavigation(showInDays);
    }
  }

  onNavigation(showInDays) {
    const { authActions, dialogOpenRequest } = this.props;

    const lastScheduledAppearance = getCookie(scheduledRegCookie.name);
    const now = Date.now();

    if (!lastScheduledAppearance) {
      setCookie(scheduledRegCookie.name, null, now);
      return;
    }

    const timePassed = now - lastScheduledAppearance;
    const nextScheduledAppearance = showInDays * ONE_DAY_IN_MILLIS;

    if (timePassed >= nextScheduledAppearance) {
      dialogOpenRequest(
        this.openScheduledRegistrationDialog,
        authActions.authDone,
      );
    }
  }

  openScheduledRegistrationDialog() {
    this.props.authActions.needsSignUpDialog();
    setCookie(scheduledRegCookie.name, null, Date.now());
  }

  render() {
    const { history, routeProps, handleDialogClose } = this.props;
    return (
      <AuthDialog
        history={history}
        routeProps={routeProps}
        handleDialogClose={handleDialogClose}
      />
    );
  }
}

export default flow(
  connectWithAuth,
  connectWithExperiments([
    WEB_SUBSEQUENT_LAUNCH_REGISTRATION_ENABLED,
    WEB_SUBSEQUENT_LAUNCH_REGISTRATION_SHOW_IN_DAYS,
  ]),
)(AuthDialogManager);
