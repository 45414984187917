import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'src/common/hooks/useWindowSize';
import { isSmall, isXSmall } from 'src/common/utils/breakpoints';
import { mintSingleton } from '../../../client/mint';
import { updateVideoAdFormat } from '../../actions/dialog';
import useActions from '../../hooks/useActions';
import { selectVideoAdDialogState } from '../../selectors/dialog';
import cssVars from '../../styles/variables';
import CommonDialog from '../shared/dialog/CommonDialog';
import VideoAdViewContainer from './VideoAdViewContainer';
import css from './videoAd.module.scss';

// NOTE: DEFAULT_WIDTH & DEFAULT_HEIGHT mirror the largest video ad format sent as an option in the adRequest params
const DEFAULT_WIDTH = 640;
const DEFAULT_HEIGHT = 480;
const AD_WIDTH_400 = 400;
const AD_WIDTH_315 = 315;
const AD_HEIGHT_350 = 350;

const calculateDialogTop = (height) => {
  if (height <= 550 || height > 900) {
    return 0;
  }

  if (height <= 800) {
    return '-50px';
  }

  if (height <= 900) {
    return '-25px';
  }
};

const baseStyles = (width, height) => ({
  dialog: {
    zIndex: cssVars['--above-top-z-index'],
    top: calculateDialogTop(height),
  },
  content: {
    // Adding on the width of the border
    width,
  },
  body: {
    borderWidth: '0px 8px 0px 8px',
  },
});

const resizedStyles = {
  dialog: {
    zIndex: cssVars['--above-top-z-index'],
    pointerEvents: 'none',
    backgroundColor: cssVars['--black'],
  },
  content: {
    pointerEvents: 'auto',
    width: '100%',
  },
  body: {
    borderWidth: '0px 8px 0px 8px',
  },
};

export default function VideoAdDialog({ breakpoint }) {
  const {
    isOpen,
    videoHeight = DEFAULT_HEIGHT,
    videoWidth = DEFAULT_WIDTH,
  } = useSelector(selectVideoAdDialogState);
  const actions = useActions({ updateVideoAdFormat });
  const [width, height] = useWindowSize();

  useEffect(() => {
    // NOTE: viewport is larger than 640 width
    if (isOpen && isSmall(breakpoint) && !isSmall(videoWidth)) {
      mintSingleton.instance?.resize(DEFAULT_WIDTH, DEFAULT_HEIGHT); // Reset videoAd to it's largest expected format
      actions.updateVideoAdFormat(DEFAULT_WIDTH, DEFAULT_HEIGHT);
      return;
    }

    // NOTE: viewport is b/w 415 & 640 width
    if (
      isOpen &&
      !isSmall(breakpoint) &&
      isXSmall(breakpoint) &&
      videoWidth !== AD_WIDTH_400
    ) {
      mintSingleton.instance?.resize(AD_WIDTH_400, AD_HEIGHT_350);
      actions.updateVideoAdFormat(AD_WIDTH_400, AD_HEIGHT_350);
      return;
    }

    // NOTE: viewport is under 415 width
    if (isOpen && !isXSmall(breakpoint) && videoWidth !== AD_WIDTH_315) {
      mintSingleton.instance?.resize(AD_WIDTH_315, AD_HEIGHT_350);
      actions.updateVideoAdFormat(AD_WIDTH_315, AD_HEIGHT_350);
    }
  }, [actions, breakpoint, isOpen, videoWidth, width]);

  const styles = isSmall(breakpoint)
    ? baseStyles(DEFAULT_WIDTH, height) // NOTE: DEFAULT_WIDTH (640) represents the small breakpoint, resizedStyles kicks in sub 640
    : resizedStyles;
  const dialogStyles = {
    ...styles.dialog,
    ...(!isOpen && { display: 'none' }),
  };

  return (
    <CommonDialog
      className="videoAdDialog"
      rootStyle={dialogStyles}
      contentClassName={css.videoAdDialogContent}
      dialogContentStyle={styles.content}
      bodyClassName={css.videoAdDialogBody}
      bodyStyle={styles.body}
      modal={false}
      autoScrollBodyContent={false}
      autoDetectWindowHeight={false}
      overlayClassName="videoAdDialogOverlay"
      overlayStyle={styles.overlay}
      titleClassName={css.videoAdDialogTitle}
      dialogOpen
      hideCornerClose
      useWideModalWidth
    >
      <VideoAdViewContainer breakpoint={breakpoint} height={videoHeight} />
    </CommonDialog>
  );
}

VideoAdDialog.propTypes = {
  breakpoint: PropTypes.number.isRequired,
};
