import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  closeUserRegistrationDialog,
  openUserRegistrationDialog,
  requestUserRegistrationDialogOpen,
} from '../../../actions/dialog';
import { logWebActivity } from '../../../actions/logging';
import web from '../../../constants/analytics/categoryActionLabel/web';
import registrationViews from '../../../constants/dialogs/registrationViews';
import {
  USER_REGISTRATION_DIALOG_BUTTON,
  USER_REGISTRATION_DIALOG_HEADER,
  USER_REGISTRATION_DIALOG_SUB_HEADER,
} from '../../../constants/localizations/userRegistration';
import { LOGIN_PATH } from '../../../constants/paths';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import breakpoints from '../../../styles/breakpoints';
import isContentPage from '../../../utils/subscription/isContentPage';
import InkButton from '../../shared/button/InkButton';
import CommonDialog from '../../shared/dialog/CommonDialog';
import GoPremiumUpsell from './GoPremiumUpsell';
import LinkWithAlexaPromo from './LinkWithAlexaPromo';
import css from './userRegistrationDialog.module.scss';

const mobileLayout = {
  bodyClassName: '',
  contentClassName: css.mobileDialogContent,
};
const desktopLayout = {
  bodyClassName: css.desktopDialogBody,
  contentClassName: css.desktopDialogContent,
};

export class UserRegistrationDialog extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      openDialog: PropTypes.func.isRequired,
      closeDialog: PropTypes.func.isRequired,
      requestDialogOpen: PropTypes.func.isRequired,
      logWebActivity: PropTypes.func.isRequired,
    }),
    breakpoint: PropTypes.number.isRequired,
    dialogIsOpen: PropTypes.bool.isRequired,
    dialogOpenIsRequested: PropTypes.bool.isRequired,
    pendingOpenRequest: PropTypes.bool.isRequired,
    routeProps: PropTypes.object.isRequired,
    dialogOpenRequest: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    isSubscriptionEnabled: PropTypes.bool.isRequired,
    dialogView: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.logAndClose = this.logAndClose.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    const { actions, dialogOpenRequest, pendingOpenRequest } = this.props;
    const { requestDialogOpen, closeDialog } = actions;

    if (pendingOpenRequest) {
      dialogOpenRequest(requestDialogOpen, closeDialog, true);
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, routeProps, dialogOpenIsRequested } = this.props;

    if (
      !prevProps.dialogOpenIsRequested &&
      dialogOpenIsRequested &&
      (routeProps.matchUrl === LOGIN_PATH ||
        routeProps.isLanding ||
        isContentPage(routeProps))
    ) {
      actions.openDialog();
      actions.logWebActivity(web.actions.show, web.labels.registrationDialog);
    }
  }

  getLayoutClasses() {
    return this.props.breakpoint <= breakpoints.BREAKPOINT_360
      ? mobileLayout
      : desktopLayout;
  }

  logAndClose() {
    this.closeDialog();
    this.props.actions.logWebActivity(
      web.actions.close,
      web.labels.registrationDialog,
    );
  }

  closeDialog() {
    const { actions, handleDialogClose } = this.props;
    actions.closeDialog();
    handleDialogClose();
  }

  render() {
    const { dialogIsOpen, dialogView, isSubscriptionEnabled } = this.props;
    const { getLocalizedText } = this.context;
    const { bodyClassName, contentClassName } = this.getLayoutClasses();
    let ImageComponent;

    if (!dialogIsOpen) {
      return null;
    }

    if (dialogIsOpen && dialogView === registrationViews.linkWithAlexa) {
      ImageComponent = (
        <LinkWithAlexaPromo onLinkWithAlexaClick={this.closeDialog} />
      );
    }

    if (
      dialogIsOpen &&
      dialogView === registrationViews.goPremium &&
      isSubscriptionEnabled
    ) {
      ImageComponent = <GoPremiumUpsell onGoPremiumClick={this.closeDialog} />;
    }

    return (
      <CommonDialog
        dialogOpen
        handleDialogClose={this.logAndClose}
        bodyClassName={classNames(css.dialogBody, bodyClassName)}
        contentClassName={classNames(css.dialogContent, contentClassName)}
        closeIconAttributes={{ className: css.closeIcon }}
        disableOverlayClickToClose
      >
        <div className={css.content}>
          <div className={css.headerContainer}>
            <h1
              id="regDialogHeader"
              data-testid="regDialogHeader"
              className={css.heading}
            >
              {getLocalizedText(USER_REGISTRATION_DIALOG_HEADER)}
            </h1>
            <p
              id="regDialogSubHeader"
              data-testid="regDialogSubHeader"
              className={css.subHeading}
            >
              {getLocalizedText(USER_REGISTRATION_DIALOG_SUB_HEADER)}
            </p>
            {ImageComponent}
          </div>
          <InkButton
            id="userRegistrationDialog"
            onClick={this.logAndClose}
            className={css.goToContentButton}
            label={getLocalizedText(USER_REGISTRATION_DIALOG_BUTTON)}
          />
        </div>
      </CommonDialog>
    );
  }
}

function mapStateToProps(state) {
  const {
    registrationDialogView,
    registrationDialogIsOpen,
    registrationDialogOpenRequested,
    pendingRegistrationDialogOpenRequest,
  } = state.dialog;
  return {
    dialogView: registrationDialogView,
    dialogIsOpen: registrationDialogIsOpen,
    dialogOpenIsRequested: registrationDialogOpenRequested,
    pendingOpenRequest: pendingRegistrationDialogOpenRequest,
    breakpoint: state.app.breakpoint,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openDialog: openUserRegistrationDialog,
        closeDialog: closeUserRegistrationDialog,
        requestDialogOpen: requestUserRegistrationDialogOpen,
        logWebActivity,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserRegistrationDialog);
