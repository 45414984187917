import classNames from 'clsx';
import PropTypes from 'prop-types';
import css from './customizableDialogView.module.scss';

function Content({ children, className }) {
  return <div className={classNames(css.content, className)}>{children}</div>;
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Content;
