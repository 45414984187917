import classNames from 'clsx';
import PropTypes from 'prop-types';
import css from './customizableDialogView.module.scss';

function Container({ className, children }) {
  return <div className={classNames(css.container, className)}>{children}</div>;
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Container;
