import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { bindActionCreators } from 'redux';
import vars from 'src/common/styles/variables';
import {
  closeTextMeTheAppDialogAndTrack,
  openTextMeTheAppDialogAndTrack,
  tapTextMeTheAppDialogAndTrack,
} from '../../actions/dialog';
import { logClientError } from '../../actions/logging';
import { textMeTheAppDialogCookie } from '../../constants/cookies';
import {
  TEXT_ME_THE_APP_DISMISS_EXPIRY,
  TEXT_ME_THE_APP_ENABLED,
  TEXT_ME_THE_APP_ENABLED_MAC,
  TEXT_ME_THE_APP_ENABLED_MAC_DESKTOP_APP,
  TEXT_ME_THE_APP_ENABLED_WINDOWS,
} from '../../constants/experiments/dialog';
import {
  CLOSE_BUTTON_LABEL,
  ERROR,
  LEGAL,
  PHONE_NUMBER_PLACEHOLDER,
  RETRY_BUTTON_LABEL,
  SUBMIT_BUTTON_LABEL,
  SUBTITLE_DID_YOU_GET_IT,
  SUBTITLE_SEND_YOURSELF_A_TEXT,
  TITLE_TAKE_TUNEIN_EVERYWHERE,
  TITLE_WE_SENT_YOU_A_LINK,
} from '../../constants/localizations/textMeTheApp';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsBranchInitialized, selectIsMobile } from '../../selectors/app';
import { selectExperiment } from '../../selectors/config';
import { selectIsTextMeTheAppDialogOpen } from '../../selectors/dialog';
import assetUrl from '../../utils/assetUrl';
import { getCookie, setCookie } from '../../utils/cookie';
import isDesktop from '../../utils/desktop/isDesktop';
import shouldShowPlayer from '../../utils/playerStatus/shouldShowPlayer';
import isMacDesktop from '../../utils/userAgent/isMacDesktop';
import isWindowsDesktop from '../../utils/userAgent/isWindowsDesktop';
import PillButton from '../shared/button/PillButton';
import commonDialogCss from '../shared/dialog/common-dialog.module.scss';
import Input from '../shared/form/Input';
import closeButtonCss from '../subscription/upsell.module.scss';
import css from './textMeTheApp.module.scss';

const fieldName = 'textMeTheAppInput';
const phoneAssetPath = 'assets/img/phone-icon-ink.svg';

const inputStyle = {
  width: '240px',
  height: '48px',
};

const hintStyle = {
  width: '160px',
  height: '16px',
  fontFamily: vars['--primary-font'],
  fontSize: '18px',
  lineHeight: 0.89,
  color: vars['--secondary-color-6'],
};

// exported for testing
export class TextMeTheAppDialog extends Component {
  static propTypes = {
    showFloatingGdprButton: PropTypes.bool.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    userAgent: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isPlayerVisible: PropTypes.bool.isRequired,
    isTextMeTheAppEnabled: PropTypes.bool,
    dismissExpiry: PropTypes.number,
    isMacAppEnabled: PropTypes.bool,
    isWindowsEnabled: PropTypes.bool,
    isMacEnabled: PropTypes.bool,
    isBranchInitialized: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  state = {
    hasError: false,
    hasSubmitted: false,
  };

  componentDidMount() {
    if (this.props.isBranchInitialized) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isBranchInitialized && this.props.isBranchInitialized) {
      this.init();
    }
  }

  init() {
    const {
      actions,
      userAgent,
      isMobile,
      isMacAppEnabled,
      isWindowsEnabled,
      isMacEnabled,
      isTextMeTheAppEnabled,
    } = this.props;

    const shouldHideOnWindows =
      isWindowsDesktop(userAgent) && !isWindowsEnabled;
    const shouldHideOnMac = isMacDesktop(userAgent) && !isMacEnabled;
    const shouldHideOnMacApp = isDesktop() && !isMacAppEnabled;

    if (
      !isTextMeTheAppEnabled || // do not show if not enabled by config
      shouldHideOnMac || // show to all mac users based on config
      shouldHideOnWindows || // show to all windows users based on config
      isMobile || // do not show on mobile
      shouldHideOnMacApp || // show on desktop app based on config
      getCookie(textMeTheAppDialogCookie.name) // do not show if cookie is present
    ) {
      return;
    }

    actions.openTextMeTheAppDialogAndTrack();
  }

  getDialogClassNames() {
    const { isPlayerVisible, showFloatingGdprButton } = this.props;

    return classNames(css.dialogStyle, {
      [css.dialogStyleWithoutPlayer]: !isPlayerVisible,
      [css.dialogStyleWithOneTrustFloatingButton]: showFloatingGdprButton,
    });
  }

  getSubmittedView() {
    const { getLocalizedText } = this.context;

    return (
      <div
        data-testid="textMeTheAppDialog"
        className={this.getDialogClassNames()}
      >
        <div className={css.dialogContents}>
          <div className={css.phoneAndTextContainer}>
            <ReactSVG
              className={css.phoneIconContainer}
              beforeInjection={(svg) => {
                // note: IE11 does not support Element.classlist for SVG elements
                svg.setAttribute('class', css.phoneIcon);
              }}
              src={assetUrl(phoneAssetPath)}
            />
            <div className={css.textContainer}>
              <h1 className={css.line1}>
                {getLocalizedText(TITLE_WE_SENT_YOU_A_LINK)}
              </h1>
              <h3 className={css.line2}>
                {getLocalizedText(SUBTITLE_DID_YOU_GET_IT)}
              </h3>
            </div>
          </div>
          <div className={css.buttonsContainer}>
            <PillButton
              className={classNames(css.button, css.leftButton)}
              id="retryButton"
              data-testid="retryButton"
              label={getLocalizedText(RETRY_BUTTON_LABEL)}
              onClick={this.handleRetry}
            />
            <PillButton
              className={classNames(css.button, css.rightButton)}
              id="closeButton"
              data-testid="closeButton"
              label={getLocalizedText(CLOSE_BUTTON_LABEL)}
              onClick={this.handleClose}
            />
          </div>
          <i
            key="textMeTheAppDialogClose"
            className={classNames(
              commonDialogCss.closeButtonSvg,
              css.closeButton,
            )}
            onClick={this.handleClose}
            id="textMeTheAppDialogClose"
            data-testid="textMeTheAppDialogClose"
          />
        </div>
      </div>
    );
  }

  getLegalTextOrError = () => {
    const { hasError } = this.state;
    const { getLocalizedText } = this.context;

    if (hasError) {
      return (
        <span
          data-testid="errorText"
          className={classNames(css.legalText, css.error)}
        >
          {getLocalizedText(ERROR)}
        </span>
      );
    }
    return (
      <span data-testid="legalText" className={css.legalText}>
        {getLocalizedText(LEGAL)}
      </span>
    );
  };

  handleClose = (event) => {
    const { dismissExpiry } = this.props;
    event?.preventDefault();
    setCookie(textMeTheAppDialogCookie.name, dismissExpiry);
    this.props.actions.closeTextMeTheAppDialogAndTrack();
  };

  handleRetry = () => {
    this.setState({
      hasSubmitted: false,
    });
  };

  handleSubmit = ({ [fieldName]: value }) => {
    const { actions } = this.props;

    // safe to use window here because this dialog won't render on the server
    // since the open function gets called in componentDidMount
    window.branch.sendSMS(value, {}, {}, (err) => {
      if (err) {
        actions.logClientError({
          message: 'textMeTheAppError',
          context: {
            error: err,
          },
        });
        this.setState({
          hasError: true,
        });
      } else {
        this.setState({
          hasError: false,
          hasSubmitted: true,
        });
        actions.tapTextMeTheAppDialogAndTrack();
      }
    });
  };

  render() {
    const { isDialogOpen, isTextMeTheAppEnabled } = this.props;
    const { getLocalizedText } = this.context;
    const { hasSubmitted } = this.state;

    if (!isDialogOpen || !isTextMeTheAppEnabled) {
      return null;
    }

    if (hasSubmitted) {
      return this.getSubmittedView();
    }

    return (
      <div
        data-testid="textMeTheAppDialog"
        className={this.getDialogClassNames()}
      >
        <div className={css.dialogContents}>
          <div className={css.phoneAndTextContainer}>
            <ReactSVG
              className={css.phoneIconContainer}
              beforeInjection={(svg) => {
                // note: IE11 does not support Element.classlist for SVG elements
                svg.setAttribute('class', css.phoneIcon);
              }}
              src={assetUrl(phoneAssetPath)}
            />
            <div className={css.textContainer}>
              <h1 className={css.line1}>
                {getLocalizedText(TITLE_TAKE_TUNEIN_EVERYWHERE)}
              </h1>
              <h3 className={css.line2}>
                {getLocalizedText(SUBTITLE_SEND_YOURSELF_A_TEXT)}
              </h3>
            </div>
          </div>
          <Form onSubmit={this.handleSubmit}>
            {({ handleSubmit, pristine }) => (
              <form data-testid="textMeTHeAppForm" onSubmit={handleSubmit}>
                <Field
                  name={fieldName}
                  id={fieldName}
                  data-testid={fieldName}
                  component={Input}
                  className={css.input}
                  style={inputStyle}
                  hintStyle={hintStyle}
                  hintText={getLocalizedText(PHONE_NUMBER_PLACEHOLDER)}
                />
                <PillButton
                  className={css.submit}
                  id="textMeTheAppGoButton"
                  label={getLocalizedText(SUBMIT_BUTTON_LABEL)}
                  type="submit"
                  isDisabled={pristine}
                />
              </form>
            )}
          </Form>
          {this.getLegalTextOrError()}
          <i
            key="textMeTheAppDialogClose"
            className={classNames(
              closeButtonCss.closeButtonSvg,
              css.closeButton,
            )}
            onClick={this.handleClose}
            id="textMeTheAppDialogClose"
            data-testid="textMeTheAppDialogClose"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDialogOpen: selectIsTextMeTheAppDialogOpen(state),
    isMobile: selectIsMobile(state),
    isPlayerVisible: shouldShowPlayer(state),
    isTextMeTheAppEnabled: selectExperiment(state, TEXT_ME_THE_APP_ENABLED),
    dismissExpiry: selectExperiment(state, TEXT_ME_THE_APP_DISMISS_EXPIRY),
    isMacAppEnabled: selectExperiment(
      state,
      TEXT_ME_THE_APP_ENABLED_MAC_DESKTOP_APP,
    ),
    isWindowsEnabled: selectExperiment(state, TEXT_ME_THE_APP_ENABLED_WINDOWS),
    isMacEnabled: selectExperiment(state, TEXT_ME_THE_APP_ENABLED_MAC),
    isBranchInitialized: selectIsBranchInitialized(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openTextMeTheAppDialogAndTrack,
        closeTextMeTheAppDialogAndTrack,
        tapTextMeTheAppDialogAndTrack,
        logClientError,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextMeTheAppDialog);
