export const TITLE_TAKE_TUNEIN_EVERYWHERE = 'textMeTheApp.title';
export const TITLE_WE_SENT_YOU_A_LINK = 'textMeTheApp.successTitle';
export const SUBTITLE_SEND_YOURSELF_A_TEXT = 'textMeTheApp.subtitle';
export const SUBTITLE_DID_YOU_GET_IT = 'textMeTheApp.successSubTitle';
export const ERROR = 'textMeTheApp.error';
export const LEGAL = 'textMeTheApp.legal';

export const PHONE_NUMBER_PLACEHOLDER = 'textMeTheApp.input.placeholder';
export const SUBMIT_BUTTON_LABEL = 'textMeTheApp.submit.button.label';

export const RETRY_BUTTON_LABEL = 'textMeTheApp.retry.button.label';
export const CLOSE_BUTTON_LABEL = 'textMeTheApp.close.button.label';
