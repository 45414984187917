import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  DOWNLOAD_DESKTOP_BUTTON,
  DOWNLOAD_DESKTOP_CONTENT,
  DOWNLOAD_DESKTOP_SUBTITLE,
  DOWNLOAD_DESKTOP_TITLE,
} from 'src/common/constants/localizations/desktop';
import {
  closeDesktopDialog,
  closeDesktopDialogAndTrack,
  openDesktopDialogAndTrack,
  tapDesktopDialogAndTrack,
} from '../../actions/dialog';
import web from '../../constants/analytics/categoryActionLabel/web';
import {
  hideMacDesktopDialogCookie as hideMacCookie,
  hideWindowsDialogCookie as hideWindowsCookie,
} from '../../constants/cookies';
import {
  WEB_MAC_DESKTOP_HREF,
  WEB_MAC_DIALOG_SHOW_AFTER_X_DAYS,
  WEB_WINDOWS_DESKTOP_HREF,
  WEB_WINDOWS_DIALOG_SHOW_AFTER_X_DAYS,
} from '../../constants/experiments/desktop';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import cssVariables from '../../styles/variables';
import { getCookie, setCookie } from '../../utils/cookie';
import { openLinkInNewTab } from '../../utils/downloadLinks';
import isContentPage from '../../utils/subscription/isContentPage';
import isMacDesktop from '../../utils/userAgent/isMacDesktop';
import isWindowsDesktop from '../../utils/userAgent/isWindowsDesktop';
import PillButton from '../shared/button/PillButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import css from '../shared/dialog/dark-theme-dialog.module.scss';

class DesktopDialog extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    userAgent: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    routeProps: PropTypes.object.isRequired,
    dialogOpenRequest: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    [WEB_MAC_DESKTOP_HREF]: PropTypes.string,
    [WEB_MAC_DIALOG_SHOW_AFTER_X_DAYS]: PropTypes.number,
    [WEB_WINDOWS_DESKTOP_HREF]: PropTypes.string,
    [WEB_WINDOWS_DIALOG_SHOW_AFTER_X_DAYS]: PropTypes.number,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props, context) {
    super(props);
    const { location } = context;

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.shouldShowDialog = this.shouldShowDialog.bind(this);

    const isWindowsBrowser = isWindowsDesktop(props.userAgent);
    const desktopTypeLabel = isWindowsBrowser
      ? web.labels.windowsDownloadPopup
      : web.labels.macDownloadPopup;
    const querySource = get(location, 'query.source');
    const label = querySource
      ? `${desktopTypeLabel}.${querySource}`
      : desktopTypeLabel;

    this.state = {
      label,
      isWindowsBrowser,
      isMacBrowser: isMacDesktop(props.userAgent),
    };
  }

  componentDidMount() {
    const { isOpen, actions, dialogOpenRequest } = this.props;

    // resets dialog state in redux store if route changed and state is still set to true
    if (isOpen) {
      actions.closeDesktopDialog();
      return;
    }

    if (this.shouldShowDialog()) {
      dialogOpenRequest(this.handleOpen, actions.closeDesktopDialog);
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      const {
        [WEB_WINDOWS_DIALOG_SHOW_AFTER_X_DAYS]: windowsCookieExpirationDays,
        [WEB_MAC_DIALOG_SHOW_AFTER_X_DAYS]: macCookieExpirationDays,
      } = this.props;

      if (this.determineWindowsDialogOpen()) {
        setCookie(hideWindowsCookie.name, windowsCookieExpirationDays);
        return;
      }

      if (this.determineMacDialogOpen()) {
        setCookie(hideMacCookie.name, macCookieExpirationDays);
      }
    }
  }

  shouldShowDialog() {
    const { isDesktop, routeProps } = this.props;

    if (isDesktop || !isContentPage(routeProps)) {
      return false;
    }

    return this.determineWindowsDialogOpen() || this.determineMacDialogOpen();
  }

  determineWindowsDialogOpen() {
    return this.state.isWindowsBrowser && !getCookie(hideWindowsCookie.name);
  }

  determineMacDialogOpen() {
    return this.state.isMacBrowser && !getCookie(hideMacCookie.name);
  }

  handleOpen() {
    this.props.actions.openDesktopDialogAndTrack(this.state.label);
  }

  handleClose() {
    const { actions, handleDialogClose } = this.props;
    actions.closeDesktopDialogAndTrack(this.state.label);
    handleDialogClose();
  }

  handleContinue() {
    const {
      actions,
      [WEB_MAC_DESKTOP_HREF]: macDesktopHref,
      [WEB_WINDOWS_DESKTOP_HREF]: windowsDesktopHref,
    } = this.props;

    actions.tapDesktopDialogAndTrack(this.state.label);

    if (this.state.isWindowsBrowser) {
      openLinkInNewTab(windowsDesktopHref);
    } else if (macDesktopHref) {
      openLinkInNewTab(macDesktopHref);
    }
  }

  render() {
    const { isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    const { getLocalizedText } = this.context;
    const dataTestId = this.state.isWindowsBrowser
      ? 'windowsDesktopDialog'
      : 'macDesktopDialog';

    return (
      <CommonDialog
        modal={false} // true to disable overlay click to close
        dialogOpen
        handleDialogClose={this.handleClose}
        bodyClassName={css.dialogBody}
        contentStyle={{
          maxWidth: 496,
          overflow: 'hidden',
        }}
        data-testid={dataTestId}
        closeIconAttributes={{
          id: `${dataTestId}CornerClose`,
          iconKey: `${dataTestId}CornerClose`,
          testId: `${dataTestId}CornerClose`,
        }}
        hasDarkTheme
      >
        <div className={css.dialogWrapper}>
          <h1
            id={`${dataTestId}Headline`}
            data-testid={`${dataTestId}Headline`}
            className={css.title}
          >
            {getLocalizedText(DOWNLOAD_DESKTOP_TITLE)}
          </h1>
          <h2 className={css.subtitle} data-testid={`${dataTestId}Subtitle`}>
            {getLocalizedText(DOWNLOAD_DESKTOP_SUBTITLE)}
          </h2>
          <div className={css.content} data-testid={`${dataTestId}Content`}>
            {getLocalizedText(DOWNLOAD_DESKTOP_CONTENT)}
          </div>
          <PillButton
            className={css.button}
            id={`${dataTestId}LinkButton`}
            data-testid={`${dataTestId}LinkButton`}
            onClick={this.handleContinue}
            backgroundColor={cssVariables['--sky']}
            label={getLocalizedText(DOWNLOAD_DESKTOP_BUTTON)}
          />
        </div>
      </CommonDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeDesktopDialog,
        openDesktopDialogAndTrack,
        closeDesktopDialogAndTrack,
        tapDesktopDialogAndTrack,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  return {
    userAgent: get(state, 'app.userAgent'),
    isDesktop: get(state, 'app.isDesktop'),
    isOpen: get(state, 'dialog.isDesktopDialogOpen'),
  };
}

export default flow(
  connectWithExperiments([
    WEB_MAC_DESKTOP_HREF,
    WEB_MAC_DIALOG_SHOW_AFTER_X_DAYS,
    WEB_WINDOWS_DESKTOP_HREF,
    WEB_WINDOWS_DIALOG_SHOW_AFTER_X_DAYS,
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(DesktopDialog);
