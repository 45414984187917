import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Children, Fragment, cloneElement } from 'react';
import css from './customizableDialogView.module.scss';

function Buttons({ className, children }) {
  const childrenElements =
    children.type === Fragment ? children.props.children : children;
  return (
    <div className={classNames(css.buttons, className)}>
      {Children.map(childrenElements, (child) =>
        cloneElement(child, {
          className: classNames(child.props.className, css.button),
        }),
      )}
    </div>
  );
}

Buttons.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Buttons;
