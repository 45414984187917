import classNames from 'clsx';
import PropTypes from 'prop-types';
import css from './customizableDialogView.module.scss';

function ContentContainer({ className, children }) {
  return (
    <div className={classNames(css.contentContainer, className)}>
      {children}
    </div>
  );
}

ContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ContentContainer;
