import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DISMISS,
  LINE_BREAK_INSTRUCTION,
  LINE_BREAK_INTERPOLATE,
} from '../../../constants/localizations/shared';
import useOnMount from '../../../hooks/useOnMount';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectIsMobile } from '../../../selectors/app';
import InkButton from '../../shared/button/InkButton';
import PillButton from '../../shared/button/PillButton';
import SkyButton from '../../shared/button/SkyButton';
import {
  Buttons,
  Container,
  Content,
  ContentContainer,
  Header,
} from '../CustomizableDialogView';
import { errorValues, themeConstants } from './constants';
import css from './messageOfTheDayView.module.scss';

function useMobileBackgroundImageUrls(
  mobileBackgroundImageUrls = {},
  headerEl,
) {
  const windowSize = useWindowSize();
  const isMobile = useSelector(selectIsMobile);
  const [headerAspectRatio, setHeaderAspectRatio] = useState(0);
  const { wide, tall } = mobileBackgroundImageUrls;

  useEffect(() => {
    if (isMobile && mobileBackgroundImageUrls && headerEl && windowSize) {
      setHeaderAspectRatio(headerEl.clientWidth / headerEl.clientHeight);
    }
  }, [headerEl, isMobile, mobileBackgroundImageUrls, windowSize]);

  if (!isMobile || !mobileBackgroundImageUrls) {
    return;
  }

  return headerAspectRatio >= 1 ? wide : tall;
}

export default function MessageOfTheDayView({
  categoryId,
  themeType,
  handleSubmit,
  handleClose,
  handleError,
  isFullScreen,
  useLegacyUi,
}) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const {
    titleKey,
    subtitleKey,
    callToActionKey,
    backgroundImageUrl,
    mobileBackgroundImageUrls,
  } = themeConstants[themeType] || {};
  const headerRef = useRef();
  const mobileBackgroundImageUrl = useMobileBackgroundImageUrls(
    mobileBackgroundImageUrls,
    headerRef?.current,
  );
  const headerStyles = {
    backgroundImage: `url(${mobileBackgroundImageUrl || backgroundImageUrl})`,
    backgroundPosition: 'center top',
  };

  useOnMount(() => {
    if (!themeConstants[themeType]) {
      handleError(`${errorValues.unsupportedCategoryId}-${categoryId}`);
    }
  });

  if (!themeConstants[themeType]) {
    return null;
  }

  return (
    <Container
      className={classNames(css.container, {
        fullScreen: isFullScreen,
        legacyUi: useLegacyUi,
      })}
    >
      <Header
        ref={headerRef}
        className={classNames(css.header, themeType)}
        overlayClassName={css.headerOverlay}
        style={headerStyles}
      />
      <ContentContainer className={css.contentContainer}>
        <Content className={css.content}>
          <div className={css.title}>
            {interpolateComponents({
              mixedString: getLocalizedText(titleKey).replace(
                LINE_BREAK_INSTRUCTION,
                LINE_BREAK_INTERPOLATE,
              ),
              components: {
                linebreak: <br />,
              },
            })}
          </div>
          <div className={css.subtitle}>{getLocalizedText(subtitleKey)}</div>
        </Content>
        <Buttons className={css.buttons}>
          {useLegacyUi ? (
            <>
              <SkyButton
                id="messageOfTheDaySubmitButton"
                onClick={handleSubmit}
                label={getLocalizedText(callToActionKey)}
                className={css.submitButton}
              />
              <PillButton
                id="messageOfTheDayDismissButton"
                onClick={handleClose}
                label={getLocalizedText(DISMISS)}
              />
            </>
          ) : (
            <>
              <InkButton
                id="messageOfTheDaySubmitButton"
                onClick={handleSubmit}
                label={getLocalizedText(callToActionKey)}
                className={css.submitButton}
              />
              <InkButton
                id="messageOfTheDayDismissButton"
                onClick={handleClose}
                label={getLocalizedText(DISMISS)}
              />
            </>
          )}
        </Buttons>
      </ContentContainer>
    </Container>
  );
}

MessageOfTheDayView.propTypes = {
  categoryId: PropTypes.string.isRequired,
  themeType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
};
