import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PauseCircleSolid from 'src/common/components/shared/svgIcons/player/PauseCircleSolid';
import PlayCircleSolid from 'src/common/components/shared/svgIcons/player/PlayCircleSolid';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import cssVariables from 'src/common/styles/variables';
import * as PlayerActions from '../../../actions/player';
import { selectPlayerStatus } from '../../../selectors/player';

export class VideoAdMediaButtons extends Component {
  static propTypes = {
    playerStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.onPauseClick = this.onPauseClick.bind(this);
    this.onPlayClick = this.onPlayClick.bind(this);
  }

  onPauseClick = () => {
    this.props.playerActions.pause();
  };

  onPlayClick = () => {
    this.props.playerActions.play();
  };

  render() {
    const buttonSize = '36';
    const { playerStatus } = this.props;
    return (
      <div>
        {playerStatus === playerStatuses.paused ? (
          <PlayCircleSolid
            width={buttonSize}
            height={buttonSize}
            onClick={this.onPlayClick}
            fillButton={cssVariables['--black']}
            fillButtonBackground={cssVariables['--anti-flash-white']}
            doNotHover
          />
        ) : (
          <PauseCircleSolid
            width={buttonSize}
            height={buttonSize}
            onClick={this.onPauseClick}
            fillButton={cssVariables['--black']}
            fillButtonBackground={cssVariables['--anti-flash-white']}
            doNotHover
          />
        )}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    playerStatus: selectPlayerStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    playerActions: bindActionCreators(PlayerActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoAdMediaButtons);
