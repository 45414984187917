import { useSelector } from 'react-redux';
import { usePalette } from '../hooks/usePalette';
import { selectNowPlaying } from '../selectors/player';

export function withPalette(ComponentToDecorate) {
  function DecoratedComponent(props) {
    const nowPlaying = useSelector(selectNowPlaying);
    const palette = usePalette(nowPlaying?.image);

    return <ComponentToDecorate {...props} palette={palette} />;
  }

  return DecoratedComponent;
}
