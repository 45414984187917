import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectIsVideoAdDialogOpen } from 'src/common/selectors/dialog';
import { allowAutoplay, disallowAutoplay } from '../../actions/app';
import { navBasedDialogManagerCookie } from '../../constants/cookies';
import { getCookie, setCookie } from '../../utils/cookie';
import AuthDialogManager from './AuthDialogManager';
import DesktopDialog from './DesktopDialog';
import MessageOfTheDayDialog from './MessageOfTheDay/MessageOfTheDayDialog';
import PremiumUpsellDialog from './PremiumUpsellDialog';
import UserRegistrationDialog from './UserRegistrationDialog/UserRegistrationDialog';

class NavBasedDialogManager extends Component {
  static propTypes = {
    routeProps: PropTypes.object.isRequired,
    isSubscriptionEnabled: PropTypes.bool.isRequired,
    disallowAutoplay: PropTypes.func.isRequired,
    allowAutoplay: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isVideoAdDialogOpen: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.cache = {
      openDialog: null,
      closeDialog: null,
      forceOpen: false,
    };

    this.dialogOpenRequest = this.dialogOpenRequest.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  // calling action in componentDidMount() to ensure dialog components have had
  // sufficient time to call dialogOpenRequest()
  componentDidMount() {
    // using setTimeout to ensure React updates action state
    setTimeout(() => {
      const dialogWasOpened = this.handleDialogOpenRequest();

      if (dialogWasOpened) {
        return;
      }

      // allow autoplay in state if no actions have been cached
      this.props.allowAutoplay();
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const { isVideoAdDialogOpen } = this.props;
    const { closeDialog } = this.cache;
    /**
     * The video ad dialog has priority over all other navigation
     * based dialogs that are managed by this component.
     */
    if (closeDialog && isVideoAdDialogOpen && !prevProps.isVideoAdDialogOpen) {
      closeDialog();
    }
  }

  componentWillUnmount() {
    // disallow autoplay on page navigation
    this.props.disallowAutoplay();
  }

  dialogOpenRequest(openDialog, closeDialog, forceOpen = false) {
    if (!this.cache.openDialog) {
      this.cache = { openDialog, closeDialog, forceOpen };
    }
  }

  /**
   * Returns a boolean indicating whether the dialog was opened via the cached
   * openDialog action.
   * @returns {boolean}
   */
  handleDialogOpenRequest() {
    if (this.props.isVideoAdDialogOpen) {
      return false;
    }

    const { openDialog, forceOpen } = this.cache;

    if (openDialog && forceOpen) {
      openDialog();
      return true;
    }

    if (openDialog && !getCookie(navBasedDialogManagerCookie.name)) {
      // calling first action in queue, as this represents highest priority dialog
      openDialog();
      setCookie(navBasedDialogManagerCookie.name, 1);
      return true;
    }

    return false;
  }

  handleDialogClose() {
    this.props.allowAutoplay();
  }

  render() {
    const {
      isSubscriptionEnabled,
      routeProps,
      history,
      showAuthAndAdDialogsOnly,
    } = this.props;

    const dialogProps = {
      routeProps,
      dialogOpenRequest: this.dialogOpenRequest,
      handleDialogClose: this.handleDialogClose,
    };

    if (showAuthAndAdDialogsOnly) {
      return <AuthDialogManager {...dialogProps} history={history} />;
    }

    // Important:
    //  - dialog render order determines priority
    //  - Components will be mounted on EVERY route
    //    change (See NavBasedDialogManager rendering in GlobalDialogs)
    return (
      <>
        <MessageOfTheDayDialog {...dialogProps} />
        <UserRegistrationDialog
          {...dialogProps}
          isSubscriptionEnabled={isSubscriptionEnabled}
        />
        {isSubscriptionEnabled && <PremiumUpsellDialog {...dialogProps} />}
        {!routeProps.restrictAuthLinksAndDialog && (
          <AuthDialogManager {...dialogProps} history={history} />
        )}
        <DesktopDialog {...dialogProps} />
      </>
    );
  }
}

const mapDispatchToProps = {
  allowAutoplay,
  disallowAutoplay,
};

const mapStateToProps = (state) => ({
  isVideoAdDialogOpen: selectIsVideoAdDialogOpen(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBasedDialogManager);
