export const MESSAGE_OF_THE_DAY_MLB_TITLE = 'messageOfTheDay.mlb.title';
export const MESSAGE_OF_THE_DAY_MLB_SUBTITLE = 'messageOfTheDay.mlb.subtitle';
export const MESSAGE_OF_THE_DAY_MLB_CTA = 'messageOfTheDay.mlb.cta';

export const MESSAGE_OF_THE_DAY_NFL_TITLE = 'messageOfTheDay.nfl.title.v2';
export const MESSAGE_OF_THE_DAY_NFL_SUBTITLE =
  'messageOfTheDay.nfl.subtitle.v2';
export const MESSAGE_OF_THE_DAY_NFL_CTA = 'messageOfTheDay.nfl.cta.v2';

export const MESSAGE_OF_THE_DAY_MAP_VIEW_TITLE =
  'messageOfTheDay.mapView.title';
export const MESSAGE_OF_THE_DAY_MAP_VIEW_SUBTITLE =
  'messageOfTheDay.mapView.subtitle';
export const MESSAGE_OF_THE_DAY_MAP_VIEW_CTA = 'messageOfTheDay.mapView.cta';

export const MESSAGE_OF_THE_DAY_BOSS_RADIO_TITLE =
  'messageOfTheDay.bossRadio.title';
export const MESSAGE_OF_THE_DAY_BOSS_RADIO_SUBTITLE =
  'messageOfTheDay.bossRadio.subtitle';
export const MESSAGE_OF_THE_DAY_BOSS_RADIO_CTA =
  'messageOfTheDay.bossRadio.cta';
export const MESSAGE_OF_THE_DAY_AUDIOBOOKS_TITLE =
  'messageOfTheDay.audiobooks.title';
export const MESSAGE_OF_THE_DAY_AUDIOBOOKS_SUBTITLE =
  'messageOfTheDay.audiobooks.subtitle';
export const MESSAGE_OF_THE_DAY_AUDIOBOOKS_CTA =
  'messageOfTheDay.audiobooks.cta';

export const MESSAGE_OF_THE_DAY_BIG_615_TITLE = 'messageOfTheDay.big615.title';
export const MESSAGE_OF_THE_DAY_BIG_615_SUBTITLE =
  'messageOfTheDay.big615.subtitle';
export const MESSAGE_OF_THE_DAY_BIG_615_CTA = 'messageOfTheDay.big615.cta';
