import classNames from 'clsx';
import PropTypes from 'prop-types';
import PillButtonLink from './PillButtonLink';
import css from './pill-button.module.scss';

const OutlinedPillButtonLink = ({ className, ...otherProps }) => (
  <PillButtonLink
    className={classNames(css.outlinedButton, className)}
    {...otherProps}
  />
);

OutlinedPillButtonLink.propTypes = {
  className: PropTypes.string,
};

export default OutlinedPillButtonLink;
