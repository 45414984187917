import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectIsUserSubscribed } from 'src/common/selectors/me';
import { clearCategory } from '../../actions/browse';
import {
  closeInterestSelectorDialog,
  fetchItemsAndOpenIntSelDialog,
  openUpsellAndTrackActivity,
} from '../../actions/dialog';
import { updateInterestSelectorItems } from '../../actions/favoriteInterests';
import { logInterestSelectorAction } from '../../actions/logging';
import feature from '../../constants/analytics/categoryActionLabel/feature';
import subscribe from '../../constants/analytics/categoryActionLabel/subscribe';
import {
  FOLLOW_BUTTON_LABEL_TRANSLATION_KEY,
  UNFOLLOW_BUTTON_LABEL_TRANSLATION_KEY,
} from '../../constants/dialogs/interestSelector';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../../constants/experiments/dialog';
import { errorLabels } from '../../constants/interestSelector';
import {
  DISMISS,
  FOLLOW,
  UNFOLLOW,
} from '../../constants/localizations/shared';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectBreakpoint, selectIsMobile } from '../../selectors/app';
import { getSelectCategory } from '../../selectors/categories';
import { selectExperiment } from '../../selectors/config';
import {
  selectInterestSelectorCategoryId,
  selectIsInterestSelectorDialogOpen,
} from '../../selectors/dialog';
import ContainerItemsLoader from '../containerItems/ContainerItemsLoader';
import PillButton from '../shared/button/PillButton';
import SkyButton from '../shared/button/SkyButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import createAndOpenSubscribeLink from '../utils/createAndOpenSubscribeLink';
import { parseSuccessDeeplink } from '../utils/interestSelector';
import {
  Buttons,
  Container,
  Content,
  ContentContainer,
  Header,
} from './CustomizableDialogView';
import css from './interestSelectorDialog.module.scss';

const matchSelected = ([, isSelected]) => isSelected;
const matchUnselected = ([, isSelected]) => !isSelected;
const pickGuideId = ([guideId]) => guideId;
const SOURCE = 'interest.selector';

// exported for testing
export class InterestSelectorDialog extends Component {
  static propTypes = {
    guideItem: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    breakpoint: PropTypes.number.isRequired,
    followButtonLocalizationKey: PropTypes.string.isRequired,
    unFollowButtonLocalizationKey: PropTypes.string.isRequired,
    isUserSubscribed: PropTypes.bool.isRequired,
    isPremiumUpsellBypassEnabled: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.pickChangedItems = this.pickChangedItems.bind(this);
  }

  state = {
    areButtonsDisabled: false,
    useUnfollowButton: false,
  };

  componentDidUpdate(prevProps) {
    const { isDialogOpen, actions, guideItem = {} } = this.props;
    if (isDialogOpen && isDialogOpen !== prevProps.isDialogOpen) {
      if (!guideItem.guideId) {
        return this.handleError(errorLabels.missingGuideId);
      }

      this.init();
      actions.logInterestSelectorAction(feature.labels.show, guideItem.guideId);
    }
  }

  init() {
    this.initialItemsMap = this.mapItemsFromGuideItem();
    this.itemsMap = new Map(this.initialItemsMap);
    this.hasPreselectedItems = Array.from(this.itemsMap).some(matchSelected);
    this.setState({
      areButtonsDisabled: false,
      useUnfollowButton: false,
      ...this.parseUpsellLabelandThemeTypeFromGuideItem(),
    });
  }

  mapItemsFromGuideItem() {
    const selectedItemsEntries =
      this.props?.guideItem?.containerItems?.flatMap?.((item) =>
        item?.children?.map?.((child) => [
          child?.guideId,
          !!child?.isSelectedInterest,
        ]),
      );

    return new Map(selectedItemsEntries);
  }

  // The Category API response contains a deeplink in the metadata field (example: `tunein://upsell?upsellscreen.override.themetype=mlb`)
  // This is used by mobile clients to open the upsell and log the upsell show event. Since we don't need the
  // deeplink, we're just parsing out:
  //  1. the label suffix to append to the upsell log
  //  2. the theme type for the upsell dialog
  parseUpsellLabelandThemeTypeFromGuideItem() {
    const successDeeplink =
      this.props.guideItem?.metadata?.properties?.continue?.successDeeplink;

    if (!successDeeplink) {
      return;
    }

    try {
      return parseSuccessDeeplink(successDeeplink);
    } catch (errorLabel) {
      this.handleError(errorLabel);
    }
  }

  handleClose(event) {
    if (event) event.preventDefault();
    this.props.actions.closeInterestSelectorDialog(true);
  }

  handleItemClick(guideId) {
    this.itemsMap.set(guideId, !this.itemsMap.get(guideId));

    if (!this.hasPreselectedItems) {
      return;
    }

    const useUnfollowButton = Array.from(this.itemsMap).every(matchUnselected);

    if (useUnfollowButton !== this.state.useUnfollowButton) {
      this.setState({ useUnfollowButton });
    }
  }

  pickChangedItems([guideId, isSelected]) {
    return isSelected !== this.initialItemsMap.get(guideId);
  }

  handleSubmit() {
    const {
      actions,
      guideItem,
      isUserSubscribed,
      isPremiumUpsellBypassEnabled,
    } = this.props;
    const { location } = this.context;
    const itemsMapEntries = Array.from(this.itemsMap).filter(
      this.pickChangedItems,
    );
    const interestsToAdd = itemsMapEntries
      .filter(matchSelected)
      .map(pickGuideId);
    const interestsToRemove = itemsMapEntries
      .filter(matchUnselected)
      .map(pickGuideId);

    if (!interestsToAdd.length && !interestsToRemove.length) {
      this.handleClose();
      return;
    }

    this.setState({ areButtonsDisabled: true }, async () => {
      // Awaiting the dispatching allows our setState callback to short-circuit silently if the API call fails.
      // If the API call fails, a redux reducer handles opening the error dialog.
      await this.updateInterests(interestsToRemove, true);
      await this.updateInterests(interestsToAdd);

      if (!isUserSubscribed) {
        if (isPremiumUpsellBypassEnabled) {
          createAndOpenSubscribeLink(guideItem, location, null, SOURCE);
        } else {
          // Awaiting this dispatch ensures we open the new dialog before closing the current dialog
          await this.openUpsellDialog();
        }
      }

      actions.clearCategory(guideItem?.guideId);
      actions.closeInterestSelectorDialog();
    });
  }

  async updateInterests(interests, removeInterests = false) {
    if (!interests.length) {
      return;
    }

    const { actions, guideItem } = this.props;
    await actions.updateInterestSelectorItems(
      interests,
      guideItem?.guideId,
      removeInterests,
    );
  }

  async openUpsellDialog() {
    const {
      actions,
      guideItem: { guideId },
    } = this.props;
    const { upsellLabelSuffix = '' } = this.state;

    const upsellLabel = upsellLabelSuffix
      ? `${subscribe.labels.interestSelectorUpsell}.${upsellLabelSuffix}`
      : subscribe.labels.interestSelectorUpsell;
    await actions.openUpsellAndTrackActivity(upsellLabel, guideId);
  }

  handleError(errorLabel) {
    const { actions, guideItem = {} } = this.props;
    actions.logInterestSelectorAction(
      feature.labels.error,
      guideItem?.guideId,
      errorLabel,
    );
  }

  render() {
    const {
      guideItem,
      isDialogOpen,
      breakpoint,
      isMobile,
      followButtonLocalizationKey,
      unFollowButtonLocalizationKey,
    } = this.props;
    const { areButtonsDisabled, useUnfollowButton } = this.state;
    const { getLocalizedText } = this.context;
    const headerStyles = { backgroundImage: `url("${guideItem.imageUrl}")` };

    if (!isDialogOpen || !guideItem.guideId) {
      return null;
    }

    return (
      <CommonDialog
        dialogOpen={isDialogOpen}
        handleDialogClose={this.handleClose}
        contentClassName={classNames({ [css.dialogContent]: !isMobile })}
        bodyClassName={css.dialogBody}
        enableFullScreen={isMobile}
        data-testid="interestSelectorDialog"
        hasDarkTheme
        hideCornerClose
        disableOverlayClickToClose
      >
        <Container
          className={classNames(css.container, {
            fullScreen: isMobile,
            legacyUi: true,
          })}
        >
          <Header
            className={css.header}
            overlayClassName={css.headerOverlay}
            style={headerStyles}
          >
            <div className={css.title}>{guideItem.title}</div>
          </Header>
          <ContentContainer className={css.contentContainer}>
            <Content className={css.content}>
              <ContainerItemsLoader
                selectedContentId={guideItem.guideId}
                items={guideItem.containerItems}
                isFetching={guideItem.isFetching}
                breakpoint={breakpoint}
                className={css.containerItems}
                onItemClick={this.handleItemClick}
                isDarkMode
                isInterestSelector
                disableFirstContainerAd
              />
            </Content>
            <Buttons className={css.buttons}>
              <SkyButton
                id="interestSelectorSubmitButton"
                onClick={this.handleSubmit}
                label={getLocalizedText(
                  useUnfollowButton
                    ? unFollowButtonLocalizationKey
                    : followButtonLocalizationKey,
                )}
                className={classNames(css.button, css.followButton)}
                isDisabled={areButtonsDisabled}
              />
              <PillButton
                id="interestSelectorDismissButton"
                onClick={this.handleClose}
                label={getLocalizedText(DISMISS)}
                className={classNames(css.button, css.cancelButton)}
                isDisabled={areButtonsDisabled}
              />
            </Buttons>
          </ContentContainer>
        </Container>
      </CommonDialog>
    );
  }
}

// exported for testing
export function mapStateToProps(state) {
  const categoryId = selectInterestSelectorCategoryId(state);

  return {
    isDialogOpen: selectIsInterestSelectorDialogOpen(state),
    guideItem: getSelectCategory(categoryId)(state) || {},
    breakpoint: selectBreakpoint(state),
    isMobile: selectIsMobile(state),
    followButtonLocalizationKey: selectExperiment(
      state,
      FOLLOW_BUTTON_LABEL_TRANSLATION_KEY,
      FOLLOW,
    ),
    unFollowButtonLocalizationKey: selectExperiment(
      state,
      UNFOLLOW_BUTTON_LABEL_TRANSLATION_KEY,
      UNFOLLOW,
    ),
    isUserSubscribed: selectIsUserSubscribed(state),
    isPremiumUpsellBypassEnabled: selectExperiment(
      state,
      BYPASS_UPSELL_DIALOG_ENABLED,
      false,
    ),
  };
}

// exported for testing
export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeInterestSelectorDialog,
        logInterestSelectorAction,
        updateInterestSelectorItems,
        fetchItemsAndOpenIntSelDialog,
        openUpsellAndTrackActivity,
        clearCategory,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterestSelectorDialog);
