import qs from 'qs';
import isServer from './isServer';
import addQuery from './queryString/addQuery';

export const iPhoneAppId = '418987775';
export const androidAppId = 'tunein.player';

const iTunesLink = `https://itunes.apple.com/us/app/tunein-radio/id${iPhoneAppId}`;
const googlePlayLink = `https://play.google.com/store/apps/details?id=${androidAppId}&hl=en`;

function addReferrerQuery(link, utmParams) {
  return addQuery(link, { referrer: qs.stringify(utmParams) });
}

export function iTunesAppUrl(utmParams) {
  return addReferrerQuery(iTunesLink, utmParams);
}

export function googlePlayAppUrl(utmParams) {
  return addReferrerQuery(googlePlayLink, utmParams);
}

export function openLinkInNewTab(href) {
  if (!isServer() && href) {
    window.open(href, '_blank', 'noopener');
  }
}
