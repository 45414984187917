import PropTypes from 'prop-types';
import cssVars from '../../../styles/variables';

const PremiumBadgeWithText = ({
  dataTestId = 'sportsIcon',
  height = '22px',
  width = '128px',
  viewBox = '0 0 128 22',
  fill = cssVars['--secondary-color-5'],
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    data-testid={dataTestId}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.696 15.0001H46V12.5761H47.32C49.204 12.5761 50.368 11.5441 50.368 9.6001C50.368 7.6681 49.204 6.6001 47.32 6.6001H43.696V15.0001ZM46 10.5241V8.6521H47.116C47.68 8.6521 48.064 8.8921 48.064 9.5641C48.064 10.2361 47.632 10.5241 47.08 10.5241H46Z"
      fill={fill}
    />
    <path
      d="M61.5163 12.1201V12.0961C62.3923 11.6281 62.8963 10.7281 62.8963 9.5161C62.8963 7.6201 61.7803 6.6001 59.8963 6.6001H56.2243V15.0001H58.5283V12.4441H59.3683L60.6403 15.0001H63.0883L61.5163 12.1201ZM59.6203 10.4041H58.5283V8.6521H59.6203C60.2803 8.6521 60.5923 8.9281 60.5923 9.5161C60.5923 10.0801 60.2443 10.4041 59.6203 10.4041Z"
      fill={fill}
    />
    <path
      d="M69.0573 15.0001H74.7693V12.9121H71.3613V11.7121H74.4573V9.8401H71.3613V8.6881H74.7333V6.6001H69.0573V15.0001Z"
      fill={fill}
    />
    <path
      d="M80.9176 15.0001H83.1736V10.4041H83.1976L85.0096 15.0001H86.1496L87.9616 10.4041H87.9856V15.0001H90.2416V6.6001H87.6136L85.5976 11.7241H85.5736L83.5456 6.6001H80.9176V15.0001Z"
      fill={fill}
    />
    <path d="M96.6685 15.0001H98.9725V6.6001H96.6685V15.0001Z" fill={fill} />
    <path
      d="M108.832 15.2521C111.028 15.2521 112.3 14.1001 112.3 12.1441V6.6001H109.996V11.9281C109.996 12.7681 109.624 13.1281 108.832 13.1281C108.004 13.1281 107.656 12.7801 107.656 11.9281V6.6001H105.352V12.1441C105.352 14.1361 106.612 15.2521 108.832 15.2521Z"
      fill={fill}
    />
    <path
      d="M118.667 15.0001H120.923V10.4041H120.947L122.759 15.0001H123.899L125.711 10.4041H125.735V15.0001H127.991V6.6001H125.363L123.347 11.7241H123.323L121.295 6.6001H118.667V15.0001Z"
      fill={fill}
    />
    <g clipPath="url(#clip0_6265_9079)">
      <path
        d="M5.72687 10.9408V9.98612C5.72687 9.88112 5.81262 9.79537 5.91762 9.79537H9.83062C9.93562 9.79537 10.0214 9.88112 10.0214 9.98612V10.9408C10.0214 11.0458 9.93562 11.1315 9.83062 11.1315H8.638C8.58025 11.1315 8.54262 11.17 8.54262 11.2269V16.2852C8.54262 16.3902 8.45687 16.476 8.35187 16.476H7.39725C7.29225 16.476 7.2065 16.3902 7.2065 16.2852V11.2269C7.2065 11.1691 7.168 11.1315 7.11112 11.1315H5.9185C5.8135 11.1315 5.72775 11.0458 5.72775 10.9408H5.72687ZM31.0231 0.96575V15.7611C31.0231 16.1566 30.7029 16.4769 30.3074 16.4769H16.2269V20.5334C16.2269 20.9289 15.9066 21.2491 15.5111 21.2491H0.71575C0.32025 21.2491 0 20.9289 0 20.5334V5.73887C0 5.34337 0.32025 5.02312 0.71575 5.02312H14.7954V0.96575C14.7954 0.57025 15.1156 0.25 15.5111 0.25H30.3065C30.702 0.25 31.0223 0.57025 31.0223 0.96575H31.0231ZM14.7963 6.45463H1.55137C1.48575 6.45463 1.43238 6.508 1.43238 6.57362V19.6986C1.43238 19.7643 1.48575 19.8176 1.55137 19.8176H14.6764C14.742 19.8176 14.7954 19.7643 14.7954 19.6986V6.45463H14.7963ZM24.7704 10.6056C24.7704 10.5006 24.6846 10.4149 24.5796 10.4149H23.7685C23.7116 10.4149 23.6731 10.3772 23.6731 10.3195V6.4065C23.6731 6.34875 23.7116 6.31112 23.7685 6.31112H24.4843C24.5893 6.31112 24.675 6.22537 24.675 6.12037V5.21387C24.675 5.10887 24.5893 5.02312 24.4843 5.02312H21.5259C21.4209 5.02312 21.3351 5.10887 21.3351 5.21387V6.12037C21.3351 6.22537 21.4209 6.31112 21.5259 6.31112H22.2416C22.2994 6.31112 22.337 6.34962 22.337 6.4065V10.3195C22.337 10.3772 22.2994 10.4149 22.2416 10.4149H21.4305C21.3255 10.4149 21.2397 10.5006 21.2397 10.6056V11.5121C21.2397 11.6171 21.3255 11.7029 21.4305 11.7029H24.5796C24.6846 11.7029 24.7704 11.6171 24.7704 11.5121V10.6056V10.6056Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_6265_9079">
        <rect
          width="31.0231"
          height="21"
          fill="white"
          transform="translate(0 0.25)"
        />
      </clipPath>
    </defs>
  </svg>
);
PremiumBadgeWithText.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
};
export default PremiumBadgeWithText;
