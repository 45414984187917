import keyMirror from 'src/common/utils/keyMirror';
import {
  messageOfTheDayCookieAudiobooks,
  messageOfTheDayCookieBig615,
  messageOfTheDayCookieBossRadio,
  messageOfTheDayCookieDefault,
  messageOfTheDayCookieMapView,
} from '../../../constants/cookies';
import {
  MESSAGE_OF_THE_DAY_AUDIOBOOKS_CTA,
  MESSAGE_OF_THE_DAY_AUDIOBOOKS_SUBTITLE,
  MESSAGE_OF_THE_DAY_AUDIOBOOKS_TITLE,
  MESSAGE_OF_THE_DAY_BIG_615_CTA,
  MESSAGE_OF_THE_DAY_BIG_615_SUBTITLE,
  MESSAGE_OF_THE_DAY_BIG_615_TITLE,
  MESSAGE_OF_THE_DAY_BOSS_RADIO_CTA,
  MESSAGE_OF_THE_DAY_BOSS_RADIO_SUBTITLE,
  MESSAGE_OF_THE_DAY_BOSS_RADIO_TITLE,
  MESSAGE_OF_THE_DAY_MAP_VIEW_CTA,
  MESSAGE_OF_THE_DAY_MAP_VIEW_SUBTITLE,
  MESSAGE_OF_THE_DAY_MAP_VIEW_TITLE,
  MESSAGE_OF_THE_DAY_MLB_CTA,
  MESSAGE_OF_THE_DAY_MLB_SUBTITLE,
  MESSAGE_OF_THE_DAY_MLB_TITLE,
  MESSAGE_OF_THE_DAY_NFL_CTA,
  MESSAGE_OF_THE_DAY_NFL_SUBTITLE,
  MESSAGE_OF_THE_DAY_NFL_TITLE,
} from '../../../constants/localizations/messageOfTheDay';

// if a theme requires a unique cookie expiration value, create a cookie in src/common/constants/cookies.js
export const themeConstants = {
  mlb: {
    titleKey: MESSAGE_OF_THE_DAY_MLB_TITLE,
    subtitleKey: MESSAGE_OF_THE_DAY_MLB_SUBTITLE,
    callToActionKey: MESSAGE_OF_THE_DAY_MLB_CTA,
    backgroundImageUrl: '/assets/img/messageOfTheDay/mlb.jpg',
    requiresCategoryId: true,
    cookieName: messageOfTheDayCookieDefault.name,
  },
  nfl: {
    titleKey: MESSAGE_OF_THE_DAY_NFL_TITLE,
    subtitleKey: MESSAGE_OF_THE_DAY_NFL_SUBTITLE,
    callToActionKey: MESSAGE_OF_THE_DAY_NFL_CTA,
    backgroundImageUrl: '/assets/img/messageOfTheDay/nfl.jpg',
    requiresCategoryId: true,
    cookieName: messageOfTheDayCookieDefault.name,
  },
  mapView: {
    titleKey: MESSAGE_OF_THE_DAY_MAP_VIEW_TITLE,
    subtitleKey: MESSAGE_OF_THE_DAY_MAP_VIEW_SUBTITLE,
    callToActionKey: MESSAGE_OF_THE_DAY_MAP_VIEW_CTA,
    backgroundImageUrl: '/assets/img/messageOfTheDay/mapView.jpg',
    requiresCategoryId: false,
    cookieName: messageOfTheDayCookieMapView.name,
  },
  bossRadio: {
    titleKey: MESSAGE_OF_THE_DAY_BOSS_RADIO_TITLE,
    subtitleKey: MESSAGE_OF_THE_DAY_BOSS_RADIO_SUBTITLE,
    callToActionKey: MESSAGE_OF_THE_DAY_BOSS_RADIO_CTA,
    backgroundImageUrl: '/assets/img/messageOfTheDay/bossRadio.jpg',
    requiresCategoryId: false,
    cookieName: messageOfTheDayCookieBossRadio.name,
  },
  audiobooks: {
    titleKey: MESSAGE_OF_THE_DAY_AUDIOBOOKS_TITLE,
    subtitleKey: MESSAGE_OF_THE_DAY_AUDIOBOOKS_SUBTITLE,
    callToActionKey: MESSAGE_OF_THE_DAY_AUDIOBOOKS_CTA,
    backgroundImageUrl: '/assets/img/messageOfTheDay/audiobooks.jpg',
    requiresCategoryId: false,
    cookieName: messageOfTheDayCookieAudiobooks.name,
  },
  big615: {
    titleKey: MESSAGE_OF_THE_DAY_BIG_615_TITLE,
    subtitleKey: MESSAGE_OF_THE_DAY_BIG_615_SUBTITLE,
    callToActionKey: MESSAGE_OF_THE_DAY_BIG_615_CTA,
    backgroundImageUrl: '/assets/img/messageOfTheDay/big615_desktop.jpg',
    mobileBackgroundImageUrls: {
      wide: '/assets/img/messageOfTheDay/big615_mobile_wide.jpg',
      tall: '/assets/img/messageOfTheDay/big615_mobile_tall.jpg',
    },
    requiresCategoryId: false,
    cookieName: messageOfTheDayCookieBig615.name,
  },
};

export const themeTypes = keyMirror(Object.keys(themeConstants));

// NOTE: [TUNE-13860] we're hard-coding this label value for now until MessageOfTheDay can be configured dynamically
export const labelValues = {
  interestSelectionDialog: 'dialogs/interestSelection',
  navigate: 'navigate',
};

export const errorValues = {
  missingCategoryId: 'missing-category-id',
  unsupportedCategoryId: 'unsupported-category-id',
};
