import PropTypes from 'prop-types';
import { useContext } from 'react';
import { WHY_ADS } from 'src/common/constants/localizations/ads';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import VideoAdCountdown from './VideoAdCountdown';
import VideoAdSlot from './VideoAdSlot';
import {
  MIDROLL_SLOT_NAME,
  PREROLL_SLOT_NAME,
  VIDEO_AD_CONTAINER_ID,
} from './constants/displayAds';
import VideoAdMediaButtons from './controls/VideoAdMediaButtons';
import css from './videoAd.module.scss';

export default function VideoAdViewContainer({ breakpoint, height }) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <>
      <div
        data-testid="videoAdTopMessageContainer"
        className={css.videoAdTopMessageContainer}
      >
        <div className={css.topOfAdMessage}>{getLocalizedText(WHY_ADS)}</div>
      </div>
      <div className={css.videoAdSlotContainer}>
        <VideoAdSlot
          id={VIDEO_AD_CONTAINER_ID}
          slotNames={[MIDROLL_SLOT_NAME, PREROLL_SLOT_NAME]}
          breakpoint={breakpoint}
          deregisterDisplaySlot={false}
          height={height}
        />
      </div>
      <div className={css.videoAdMediaButtonsContainer}>
        <VideoAdMediaButtons />
      </div>
      <div className={css.videoAdCountdownContainer}>
        <VideoAdCountdown />
      </div>
    </>
  );
}

VideoAdViewContainer.propTypes = {
  breakpoint: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
