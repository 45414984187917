/* eslint-disable max-len */
// Localizations for the Device Settings page
export const HEADLINE = 'account.devices.headline';
export const ADD_DEVICE_TITLE = 'account.devices.addDevice.title';
export const ADD_DEVICE_BUTTON = 'account.devices.addDeviceButton';
export const ADD_DEVICE_LINE_2 = 'account.devices.addDeviceLine2';
export const LOAD_ERROR = 'account.devices.loadError';

export const ADD_DEVICE_HEADLINE = 'account.devices.addDevice.headline';
export const ADD_DEVICE_REGISTRATION_CODE_PLACEHOLDER =
  'account.devices.addDevice.registrationCode.placeholder';
export const ADD_DEVICE_CODE_INVALID_ERROR =
  'account.devices.addDevice.code.invalid.error';
export const ADD_DEVICE_SUCCESS = 'account.devices.addDevice.success';

export const CANNOT_DELETE_DEVICE_TITLE =
  'account.devices.cannotDeleteDevice.title';
export const CANNOT_DELETE_DEVICE_BODY =
  'account.devices.cannotDeleteDevice.body';

export const DELETE_DEVICE_ERROR = 'account.devices.deleteDevice.error';
export const DELETE_DEVICE_HEADLINE = 'account.devices.deleteDevice.headline';
export const DELETE_DEVICE_BODY_TEXT = 'account.devices.deleteDevice.bodyText';
export const DELETE_DEVICE_CHECKBOX_LABEL =
  'account.devices.deleteDevice.checkbox.label';
export const DELETE_DEVICE_REMOVE_DEVICE =
  'account.devices.deleteDevice.removeDevice';
export const DELETE_DEVICE_TITLE_REMOVE_DEVICE =
  'account.devices.deleteDevice.title.removeDevice';
export const DELETE_DEVICE_CONFIRM_DELETE =
  'account.devices.deleteDevice.confirmDelete';

export const ALEXA_DIALOG_TITLE = 'account.devices.alexa.dialog.title';
export const ALEXA_DIALOG_SUBTITLE = 'account.devices.alexa.dialog.subtitle';
export const ALEXA_DIALOG_SUCCESS_TITLE =
  'account.devices.alexa.dialog.success.title';
export const ALEXA_DIALOG_FAIL_TITLE =
  'account.devices.alexa.dialog.fail.title';
export const ALEXA_DIALOG_BUTTON_LABEL =
  'account.devices.alexa.dialog.button.label';
export const ALEXA_DIALOG_SUCCESS_BUTTON_LABEL =
  'account.devices.alexa.dialog.success.button.label';
export const LINK_WITH_ALEXA_BUTTON_LABEL =
  'account.devices.alexa.button.label.unlinked';
export const LINKED_WITH_ALEXA_BUTTON_LABEL =
  'account.devices.alexa.button.label.linked';
