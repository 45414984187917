export const USER_REGISTRATION_DIALOG_HEADER =
  'user.registration.dialog.header';
export const USER_REGISTRATION_DIALOG_SUB_HEADER =
  'user.registration.dialog.sub.header';
export const USER_REGISTRATION_DIALOG_TEXT = 'user.registration.dialog.text';
export const USER_REGISTRATION_DIALOG_BUTTON =
  'user.registration.dialog.button';
export const USER_REGISTRATION_DIALOG_LISTEN_ALEXA =
  'user.registration.dialog.listen.alexa';
export const USER_REGISTRATION_DIALOG_GO_PREMIUM_SUBTEXT =
  'user.registration.dialog.go.premium.subtext';
export const USER_REGISTRATION_DIALOG_GO_PREMIUM_CTA_TEXT =
  'user.registration.dialog.go.premium.cta.text';
export const USER_REGISTRATION_DIALOG_LINK_ALEXA_SUBTEXT_ONE =
  'user.registration.dialog.link.alexa.subtext.one';
export const USER_REGISTRATION_DIALOG_LINK_ALEXA_SUBTEXT_TWO =
  'user.registration.dialog.link.alexa.subtext.two';
export const USER_REGISTRATION_DIALOG_LINK_ALEXA_CTA_TEXT =
  'user.registration.dialog.link.alexa.cta.text';
