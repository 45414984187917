import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  closeMessageOfTheDayDialog,
  fetchItemsAndOpenIntSelDialog,
  openMessageOfTheDayDialog,
} from '../../../actions/dialog';
import { logMessageOfTheDayAction } from '../../../actions/logging';
import feature from '../../../constants/analytics/categoryActionLabel/feature';
import { navBasedDialogManagerCookie } from '../../../constants/cookies';
import {
  MESSAGE_OF_THE_DAY_CATEGORY_ID,
  MESSAGE_OF_THE_DAY_COOKIE_EXPIRATION_DAYS,
  MESSAGE_OF_THE_DAY_ENABLED,
  MESSAGE_OF_THE_DAY_NAVIGATE_ON_SUBMIT,
  MESSAGE_OF_THE_DAY_THEME_TYPE,
  MESSAGE_OF_THE_DAY_USE_LEGACY_UI,
} from '../../../constants/dialogs/messageOfTheDay';
import connectWithExperiments from '../../../decorators/connectWithExperiments';
import { getCookie, setCookie } from '../../../utils/cookie';
import isContentPage from '../../../utils/subscription/isContentPage';
import CommonDialog from '../../shared/dialog/CommonDialog';
import MessageOfTheDayView from './MessageOfTheDayView';
import { errorValues, labelValues, themeConstants } from './constants';
import css from './messageOfTheDayDialog.module.scss';

// exported for testing
export class MessageOfTheDayDialog extends Component {
  static propTypes = {
    routeProps: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isDialogOpen: PropTypes.bool.isRequired,
    dialogOpenRequest: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    [MESSAGE_OF_THE_DAY_COOKIE_EXPIRATION_DAYS]: PropTypes.number,
    [MESSAGE_OF_THE_DAY_ENABLED]: PropTypes.bool,
    [MESSAGE_OF_THE_DAY_CATEGORY_ID]: PropTypes.string.isRequired,
    [MESSAGE_OF_THE_DAY_THEME_TYPE]: PropTypes.string.isRequired,
    [MESSAGE_OF_THE_DAY_USE_LEGACY_UI]: PropTypes.bool,
    [MESSAGE_OF_THE_DAY_NAVIGATE_ON_SUBMIT]: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  get currentTheme() {
    const { [MESSAGE_OF_THE_DAY_THEME_TYPE]: themeType } = this.props;
    return themeConstants[themeType] || {};
  }

  componentDidMount() {
    const {
      routeProps,
      dialogOpenRequest,
      [MESSAGE_OF_THE_DAY_ENABLED]: isMessageOfTheDayDialogEnabled,
    } = this.props;
    const hasCookie = getCookie(this.currentTheme.cookieName);

    if (
      isMessageOfTheDayDialogEnabled &&
      !hasCookie &&
      isContentPage(routeProps)
    ) {
      const navDialogCookie = getCookie(navBasedDialogManagerCookie.name);
      dialogOpenRequest(this.handleOpen, this.handleClose, navDialogCookie);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isDialogOpen,
      actions,
      [MESSAGE_OF_THE_DAY_CATEGORY_ID]: categoryId,
      [MESSAGE_OF_THE_DAY_THEME_TYPE]: themeType,
    } = this.props;

    if (isDialogOpen && !prevProps.isDialogOpen) {
      if (!this.currentTheme.requiresCategoryId || categoryId) {
        actions.logMessageOfTheDayAction(
          feature.labels.show,
          this.currentTheme.requiresCategoryId && categoryId
            ? `${themeType}.${categoryId}`
            : themeType,
        );
      } else {
        this.handleError(errorValues.missingCategoryId);
      }
    }
  }

  handleError(errorMessage) {
    const { actions } = this.props;
    actions.closeMessageOfTheDayDialog();
    actions.logMessageOfTheDayAction(feature.labels.error, errorMessage);
  }

  // we only use this handle open method when opening via the MESSAGE_OF_THE_DAY_ENABLED config on page load
  handleOpen() {
    const {
      actions,
      [MESSAGE_OF_THE_DAY_COOKIE_EXPIRATION_DAYS]: cookieExpirationDays,
    } = this.props;

    actions.openMessageOfTheDayDialog();
    setCookie(this.currentTheme.cookieName, cookieExpirationDays, true);
  }

  handleClose(event) {
    if (event) event.preventDefault();

    const {
      actions,
      [MESSAGE_OF_THE_DAY_CATEGORY_ID]: categoryId,
      [MESSAGE_OF_THE_DAY_THEME_TYPE]: themeType,
      handleDialogClose,
    } = this.props;

    handleDialogClose();
    actions.closeMessageOfTheDayDialog();
    actions.logMessageOfTheDayAction(
      feature.labels.cancel,
      this.currentTheme.requiresCategoryId && categoryId
        ? `${themeType}.${categoryId}`
        : themeType,
    );
  }

  async handleSubmit() {
    const {
      actions,
      history,
      [MESSAGE_OF_THE_DAY_CATEGORY_ID]: categoryId,
      [MESSAGE_OF_THE_DAY_THEME_TYPE]: themeType,
      [MESSAGE_OF_THE_DAY_COOKIE_EXPIRATION_DAYS]: cookieExpirationDays,
      [MESSAGE_OF_THE_DAY_NAVIGATE_ON_SUBMIT]: navigateOnSubmit,
    } = this.props;
    let labelValue;

    setCookie(this.currentTheme.cookieName, cookieExpirationDays, true);

    if (navigateOnSubmit) {
      history.push(navigateOnSubmit);
      labelValue = `${labelValues.navigate}.${themeType}.${navigateOnSubmit}`;
    } else {
      // NOTE: in the future, MessageOfTheDay might perform some other action on submit. For now, we're just
      // hard-coding the opening of the interest selection dialog the label value.
      await actions.fetchItemsAndOpenIntSelDialog({ categoryId });
      labelValue = `${labelValues.interestSelectionDialog}?categoryId=${categoryId}.${themeType}.${categoryId}`;
    }

    actions.logMessageOfTheDayAction(feature.labels.tap, labelValue);
    actions.closeMessageOfTheDayDialog();
  }

  render() {
    const {
      isDialogOpen,
      isMobile,
      [MESSAGE_OF_THE_DAY_CATEGORY_ID]: categoryId,
      [MESSAGE_OF_THE_DAY_THEME_TYPE]: themeType,
      [MESSAGE_OF_THE_DAY_USE_LEGACY_UI]: useLegacyUi,
    } = this.props;

    if (!isDialogOpen || !categoryId) {
      return null;
    }

    return (
      <CommonDialog
        dialogOpen={isDialogOpen}
        handleDialogClose={this.handleClose}
        contentClassName={classNames({ [css.dialogContent]: !isMobile })}
        bodyClassName={classNames(css.dialogBody, {
          [css.fullScreen]: isMobile,
        })}
        enableFullScreen={isMobile}
        data-testid="messageOfTheDayDialog"
        hasDarkTheme={!!useLegacyUi}
        hideCornerClose
        disableOverlayClickToClose
      >
        <MessageOfTheDayView
          categoryId={categoryId}
          themeType={themeType}
          useLegacyUi={useLegacyUi}
          handleSubmit={this.handleSubmit}
          handleClose={this.handleClose}
          handleError={this.handleError}
          isFullScreen={isMobile}
        />
      </CommonDialog>
    );
  }
}

// exported for testing
export function mapStateToProps(state) {
  const { dialog, app } = state;

  return {
    isDialogOpen: dialog.isMessageOfTheDayDialogOpen,
    breakpoint: app.breakpoint,
    isMobile: app.isMobile,
  };
}

// exported for testing
export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openMessageOfTheDayDialog,
        closeMessageOfTheDayDialog,
        fetchItemsAndOpenIntSelDialog,
        logMessageOfTheDayAction,
      },
      dispatch,
    ),
  };
}

export default flow(
  connectWithExperiments([
    MESSAGE_OF_THE_DAY_ENABLED,
    MESSAGE_OF_THE_DAY_COOKIE_EXPIRATION_DAYS,
    MESSAGE_OF_THE_DAY_CATEGORY_ID,
    MESSAGE_OF_THE_DAY_THEME_TYPE,
    MESSAGE_OF_THE_DAY_USE_LEGACY_UI,
    MESSAGE_OF_THE_DAY_NAVIGATE_ON_SUBMIT,
  ]),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(MessageOfTheDayDialog);
