import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import canEmbed from 'src/common/utils/guideItem/canEmbed';
import { closeShareDialog } from '../../actions/dialog';
import ShareDialog from '../shared/dialog/ShareDialog';

export const ShareDialogContainer = ({
  guideItem,
  isShareDialogOpen,
  actions,
}) => {
  if (!isShareDialogOpen) {
    return null;
  }

  const shareMeta = {
    guideId: guideItem.guideId,
    image: guideItem.image,
    title: guideItem.title,
    subtitle: guideItem.subtitle,
    shareUrl: get(guideItem, 'actions.share.shareUrl'),
    shareText: get(guideItem, 'actions.share.shareText'),
    slogan: get(guideItem, 'metadata.properties.station.slogan'),
  };

  return (
    <ShareDialog
      shareMeta={shareMeta}
      showEmbed={canEmbed(guideItem)}
      isActive={isShareDialogOpen}
      toggleShareDialog={actions.closeShareDialog}
    />
  );
};

ShareDialogContainer.propTypes = {
  guideItem: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isShareDialogOpen: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ closeShareDialog }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    isShareDialogOpen: get(state, 'dialog.isShareDialogOpen', false),
    guideItem: get(state, 'dialog.shareDialogItem', {}),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShareDialogContainer);
