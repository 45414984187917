import classNames from 'clsx';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cancelInAppMessageDialogOpenRequest } from 'src/common/actions/dialog';
import { logWebActivity } from 'src/common/actions/logging';
import { seek } from 'src/common/actions/player';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import web from '../../constants/analytics/categoryActionLabel/web';
import { inAppMessageDialogCookie } from '../../constants/cookies';
import inAppMessageDialogKeys from '../../constants/dialogs/inAppMessageDialogKeys';
import { IN_APP_MESSAGE_DIALOG_DISMISS_EXPIRY } from '../../constants/experiments/dialog';
import { selectExperiment } from '../../selectors/config';
import {
  selectInAppMessageDialogContent,
  selectInAppMessageGuideId,
  selectShouldInAppMessageDialogOpen,
} from '../../selectors/dialog';
import { selectNowPlaying, selectPlayerStatus } from '../../selectors/player';
import { getCookie, setCookie } from '../../utils/cookie';
import PillButton from '../shared/button/PillButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import css from '../shared/dialog/dark-theme-dialog.module.scss';

const dataTestId = 'inAppMessageDialog';
const imageClassMap = {
  [inAppMessageDialogKeys.backBuffer]: css.backBufferIcon,
};
const buttonActions = {
  JumpToStart: (actions) => actions.seek(0),
  JumpToLive: (actions) => actions.seek(100),
};
const { connecting, failed } = playerStatuses;
const allowedPlayerStates = [connecting, failed];

class InAppMessageDialog extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    playerStatus: PropTypes.string.isRequired,
    shouldOpen: PropTypes.bool.isRequired,
    content: PropTypes.object,
    guideId: PropTypes.string,
    primaryGuideId: PropTypes.string,
    inAppMessageDialogExpirationDays: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.handleClose = this.handleClose.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { shouldOpen, primaryGuideId, actions, content } = this.props;

    if (shouldOpen !== prevProps.shouldOpen && shouldOpen) {
      this.determineDialogBehavior();
    }

    if (
      get(content, 'key') === inAppMessageDialogKeys.backBuffer &&
      prevProps.primaryGuideId !== primaryGuideId
    ) {
      actions.cancelInAppMessageDialogOpenRequest();
    }
  }

  determineDialogBehavior() {
    const { actions, guideId, inAppMessageDialogExpirationDays } = this.props;

    const cookieName = `${inAppMessageDialogCookie.name}-${guideId}`;

    if (getCookie(cookieName)) {
      // if cookie exists, close and do NOT log web activity
      actions.cancelInAppMessageDialogOpenRequest();
    } else {
      actions.logWebActivity(web.actions.show, web.labels.inAppMessage);
      setCookie(cookieName, inAppMessageDialogExpirationDays);
      this.setState({ isOpen: true });
    }
  }

  handleClose(event) {
    if (event) event.preventDefault();
    const { actions } = this.props;
    actions.cancelInAppMessageDialogOpenRequest();
    actions.logWebActivity(web.actions.close, web.labels.inAppMessage);
    this.setState({ isOpen: false });
  }

  handleContinue(destination) {
    const { actions } = this.props;

    actions.cancelInAppMessageDialogOpenRequest();
    actions.logWebActivity(web.actions.tap, web.labels.inAppMessage);
    this.setState({ isOpen: false });

    const btnAction = buttonActions[destination];
    if (btnAction) {
      btnAction(actions);
    }
  }

  render() {
    const { content, playerStatus } = this.props;
    const { isOpen } = this.state;

    if (!content || !isOpen || allowedPlayerStates.includes(playerStatus)) {
      return null;
    }

    return (
      <CommonDialog
        modal={false} // true to disable overlay click to close
        dialogOpen
        handleDialogClose={this.handleClose}
        data-testid={dataTestId}
        bodyClassName={css.dialogBody}
        contentStyle={{
          maxWidth: 510,
          overflow: 'hidden',
        }}
        closeIconAttributes={{
          id: `${dataTestId}CornerClose`,
          testId: `${dataTestId}CornerClose`,
          iconKey: `${dataTestId}CornerClose`,
        }}
        hasDarkTheme
      >
        <div className={classNames(css.dialogWrapper, css.compact)}>
          <div
            className={classNames(css.iconBlock, imageClassMap[content.key])}
          />
          <h1
            id={`${dataTestId}Headline`}
            data-testid={`${dataTestId}Headline`}
            className={classNames(css.subtitle, css.sky, css.bold)}
          >
            {content.title}
          </h1>
          <div
            className={classNames(css.content, css.compact)}
            data-testid={`${dataTestId}Content`}
          >
            {content.subtitle}
          </div>
          <div className={css.buttonRow}>
            {content.buttons.map((button, index) => (
              <div className={css.buttonWrapper} key={index}>
                <PillButton
                  key={index}
                  className={css.button}
                  id={`${dataTestId}Button-${index}`}
                  data-testid={`${dataTestId}Button-${index}`}
                  onClick={() => this.handleContinue(button.data)}
                  backgroundColor="#FFFFFF"
                  label={button.text}
                />
              </div>
            ))}
          </div>
        </div>
      </CommonDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    shouldOpen: selectShouldInAppMessageDialogOpen(state),
    content: selectInAppMessageDialogContent(state),
    guideId: selectInAppMessageGuideId(state),
    playerStatus: selectPlayerStatus(state),
    primaryGuideId: selectNowPlaying(state)?.primaryGuideId,
    inAppMessageDialogExpirationDays:
      selectExperiment(state, IN_APP_MESSAGE_DIALOG_DISMISS_EXPIRY) || 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logWebActivity,
        cancelInAppMessageDialogOpenRequest,
        seek,
      },
      dispatch,
    ),
  };
}

export default flow(connect(mapStateToProps, mapDispatchToProps))(
  InAppMessageDialog,
);
