import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  USER_REGISTRATION_DIALOG_LINK_ALEXA_CTA_TEXT,
  USER_REGISTRATION_DIALOG_LINK_ALEXA_SUBTEXT_ONE,
  USER_REGISTRATION_DIALOG_LINK_ALEXA_SUBTEXT_TWO,
} from 'src/common/constants/localizations/userRegistration';
import { openLinkWithAlexaDialog } from '../../../actions/dialog';
import { logAccountActivity } from '../../../actions/logging';
import account from '../../../constants/analytics/categoryActionLabel/account';
import withAlexaLinking from '../../../decorators/withAlexaLinking';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import assetUrl from '../../../utils/assetUrl';
import OutlinedPillButton from '../../shared/button/OutlinedPillButton';
import css from './userRegistrationDialog.module.scss';

export class LinkWithAlexaPromo extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      openLinkWithAlexaDialog: PropTypes.func.isRequired,
      logAccountActivity: PropTypes.func.isRequired,
    }),
    onLinkWithAlexaClick: PropTypes.func.isRequired,
    isAlexaLinkingEnabled: PropTypes.bool,
    isAlexaLinked: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.linkYourAccount = this.linkYourAccount.bind(this);
  }

  linkYourAccount() {
    const { actions, onLinkWithAlexaClick } = this.props;
    actions.logAccountActivity(account.actions.click, [
      account.labels.linkAlexa,
    ]);
    actions.openLinkWithAlexaDialog();
    onLinkWithAlexaClick();
  }

  render() {
    const { getLocalizedText } = this.context;
    const { isAlexaLinked, isAlexaLinkingEnabled } = this.props;

    if (!isAlexaLinkingEnabled || isAlexaLinked) {
      return null;
    }

    return (
      <div
        data-testid="LinkAlexaImage"
        className={classNames(css.creativeWrapper, css.linkWithAlexa)}
      >
        <img
          className={css.alexaImage}
          src={assetUrl('assets/img/alexa.png')}
          alt="Link Alexa Registration"
        />
        <p className={css.creativeSubtext}>
          {getLocalizedText(USER_REGISTRATION_DIALOG_LINK_ALEXA_SUBTEXT_ONE)}
          <br />
          {getLocalizedText(USER_REGISTRATION_DIALOG_LINK_ALEXA_SUBTEXT_TWO)}
        </p>
        <OutlinedPillButton
          label={getLocalizedText(USER_REGISTRATION_DIALOG_LINK_ALEXA_CTA_TEXT)}
          className={css.userDialogCtaButton}
          onClick={this.linkYourAccount}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openLinkWithAlexaDialog,
        logAccountActivity,
      },
      dispatch,
    ),
  };
}
export default flow(
  connect(null, mapDispatchToProps),
  withAlexaLinking,
)(LinkWithAlexaPromo);
