import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeDownloadAppDialogAndTrack } from 'src/common/actions/dialog';
import { logMobileWebActivity } from 'src/common/actions/logging';
import mobileWeb from 'src/common/constants/analytics/categoryActionLabel/mobileWeb';
import {
  DOWNLOAD_APP_DIALOG_BUTTON_TEXT,
  DOWNLOAD_APP_DIALOG_TEXT,
} from 'src/common/constants/localizations/downloadAppDialog';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectBranchUrl } from '../../selectors/app';
import { selectIsDownloadAppDialogOpen } from '../../selectors/dialog';
import cssVariables from '../../styles/variables';
import sendToAppDownload from '../../utils/sendToAppDownload';
import PillButton from '../shared/button/PillButton';
import CommonDialog from '../shared/dialog/CommonDialog';
import css from './downloadAppDialog.module.scss';

const idPrefix = 'downloadAppDialog';

class DownloadAppDialog extends Component {
  static propTypes = {
    isDownloadAppDialogOpen: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    branchUrl: PropTypes.string.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  handleClose = () => this.props.actions.closeDownloadAppDialogAndTrack();

  render() {
    const { actions, branchUrl, isDownloadAppDialogOpen } = this.props;
    const { getLocalizedText } = this.context;

    if (!isDownloadAppDialogOpen) {
      return null;
    }

    return (
      <CommonDialog
        modal
        bodyClassName={css.dialogBody}
        dialogOpen
        handleDialogClose={this.handleClose}
        contentStyle={{
          position: 'absolute',
          top: 0,
          width: '100%',
          opacity: '0.99',
          // material-ui hack so Dialog covers entire screen
          transform: 'none !important',
          maxWidth: 'none !important',
        }}
        data-testid={idPrefix}
        closeIconAttributes={{
          testId: `${idPrefix}CornerClose`,
        }}
        style={{ zIndex: cssVariables['--above-top-z-index'] }}
        hasDarkTheme
      >
        <div className={css.innerContent}>
          <div
            className={css.downloadIconWrapper}
            data-testid={`${idPrefix}DownloadIcon`}
          >
            <div className={css.downloadIcon} />
          </div>
          <h1 data-testid={`${idPrefix}Text`}>
            {interpolateComponents({
              mixedString: getLocalizedText(DOWNLOAD_APP_DIALOG_TEXT),
              components: { linebreak: <br /> },
            })}
          </h1>
          <br />
          <PillButton
            id="appDialogContinueListening"
            label={getLocalizedText(DOWNLOAD_APP_DIALOG_BUTTON_TEXT)}
            onClick={() =>
              sendToAppDownload(
                branchUrl,
                mobileWeb.labels.appDownloadDialog,
                actions.logMobileWebActivity,
              )
            }
            className={css.continueListening}
            dataTestId={`${idPrefix}ContinueListeningButton`}
          />
        </div>
      </CommonDialog>
    );
  }
}

export function mapStateToProps(state) {
  const isDownloadAppDialogOpen = selectIsDownloadAppDialogOpen(state);
  const branchUrl = selectBranchUrl(state);
  return {
    isDownloadAppDialogOpen,
    branchUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeDownloadAppDialogAndTrack,
        logMobileWebActivity,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppDialog);
